import { useContext, useState } from "react";
import "./ProductInformations.scss";
import Button from "../Button/Button";
import { Link } from "react-router-dom";
import { ShopContext } from "../../Context/ShopContext";
import { v4 as uuidv4 } from 'uuid';
import { Swiper, SwiperSlide } from "swiper/react";
import FeatureSwiper from "../FeatureSwiper/FeatureSwiper";
import useSwalAlert from "../../hooks/useSwalAlert";
const ProductInformations = ({ product }) => {
  const showAlert = useSwalAlert();
  const { addToCart } = useContext(ShopContext);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedOutdoor, setSelectedOutdoor] = useState(
    product?.outdoors.length ? product?.outdoors[0] : null
  );
  const [selectedBackboard, setSelectedBackboard] = useState(
    product?.backBoards.length ? product?.backBoards[0] : null
  );

  const [selectedSize, setSelectedSize] = useState(
    product?.sizes.length ? product?.sizes[0] : null
  );
  const [selectedColor, setSelectedColor] = useState(
    product?.colors.length ? product?.colors[0] : null
  );

  const [selectedBacboardColor, setSelectedBackboardColor] = useState(
    product?.backBoardColors.length ? product.backBoardColors[0] : null
  );

  const [selectedOptions, setSelectedOptions] = useState([]);

  const [numberOfItems, setNumberOfItems] = useState(1);
  
  

  const handleNumberOfItemsChange = (e) => {
    if (e.target.value < 0 || e.target.value > 1000) {
      alert("Number of items must be in range 1-1000");
      return;
    }
    setNumberOfItems(e.target.value);
  };
  const handlePlusClick = () => {
    let number = parseInt(numberOfItems);
    number++;
    if (number > 1000) {
      alert("Number of items must be in range 1-1000");
      return;
    }
    setNumberOfItems(number);
  };
  const handleMinusClick = () => {
    let number = parseInt(numberOfItems);
    number--;
    if (number < 0) {
      alert("Number of items must be in range 1-1000");
      return;
    }
    setNumberOfItems(number);
  };

  const handleOptionChange = (option) => {
    const optionIndex = selectedOptions.findIndex(
      (selectedOption) => selectedOption.id === option.id
    );

    if (optionIndex === -1) {
      setSelectedOptions([...selectedOptions, option]);
    } else {
      const updatedOptions = [...selectedOptions];
      updatedOptions.splice(optionIndex, 1);
      setSelectedOptions(updatedOptions);
    }
  };

  const handleAddToCart = () => {
    const item = {
      type:'product',
      // ...product,
      productId:product.id,
      title:product.title,
      price:product.price,
      image:product.image,
      selectedColor:selectedColor?.title,
      color:selectedColor?.id,
      selectedSize: selectedSize?.title,
      size:selectedSize?.id,
      selectedOutdoor: selectedOutdoor?.id,
      selectedBackboard: selectedBackboard?.id,
      selectedBacboardColor: selectedBacboardColor?.id,
      selectedOptions: selectedOptions?.map(option=>option.id),
      itemId: uuidv4(),
      quantity: numberOfItems,
    };
    addToCart(item);
    showAlert('Item was added','','');
  };

  return (
    <div className="product-informations">
      <div className="main-section">
        <div className="images-section">
          <div className="main-image">
            {product.image && (
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${
                  selectedImage ? selectedImage : product.image
                }`}
                alt={product.title}
              />
            )}
          </div>

          {product.images && (
            <div className="images-swiper">
              <Swiper
                slidesPerView={4}
                centerInsufficientSlides
                spaceBetween={10}
                loop
              >
                {JSON.parse(product.images).map((image, index) => (
                  <SwiperSlide key={index}>
                    <img
                      onClick={() => {
                        setSelectedImage(image);
                      }}
                      src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${image}`}
                      alt=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>

        <div className="product-details">
          <h3 className="title">{product.title}</h3>
          {product.oldPrice && (
            <h4 className="old-price">£{product.oldPrice}</h4>
          )}
          <h4 className="price">£{product.price}</h4>
          <p className="description">{product.description}</p>
          {selectedSize && (
            <h3 className="current-size">
              <span>Size:</span> {selectedSize.title} {selectedSize.value}
            </h3>
          )}
          {product.sizes.length ? (
            <div className="sizes-grid">
              {product.sizes.map((size) => (
                <div
                  key={size.id}
                  className={`size ${
                    selectedSize && selectedSize.title === size.title ? "active" : ""
                  }`}
                  onClick={() => {
                    setSelectedSize(size);
                  }}
                >
                  <h5 className="size-title">{size.title}</h5>
                  <h5 className="size-value">{size.value}</h5>
                  <h5 className="size-price">£{size.price}</h5>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}
          {product.colors.length ? (
            <div className="color-picker">
              {selectedColor && (
                <h3 className="current-color">
                  <span>Color:</span> {selectedColor.title}
                </h3>
              )}
              <div className="colors-grid">
                {product.colors.map((color) => (
                  <div
                    className={`color ${
                      selectedColor && color.title === selectedColor.title ? "active" : ""
                    }`}
                    key={color.id}
                    onClick={() => {
                      setSelectedColor(color);
                    }}
                  >
                    {color.image ? (
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${color.image}`}
                        alt={color.title}
                      />
                    ) : (
                      <svg
                        className="color-svg"
                        style={{ background: color.value }}
                      ></svg>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <></>
          )}

          {product.outdoors.length ? (
            <>
              <div className="title">indoor outdoor</div>
              <div className="indoor-grid">
                {product.outdoors.map((outdoor) => (
                  <div
                    className={`door-type ${
                      selectedOutdoor && selectedOutdoor.id === outdoor.id
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedOutdoor(outdoor);
                    }}
                    key={outdoor.id}
                  >
                    <h6 className="door-title">{outdoor.title}</h6>
                    <p className="door-price">£{outdoor.price}</p>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}

          {product.backBoards.length ? (
            <>
              <div className="title">Backboard Style</div>
              <div className="backborads-grid">
                {product.backBoards.map((backBoard) => (
                  <div
                    className={`backboard ${
                      selectedBackboard && selectedBackboard.id === backBoard.id
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedBackboard(backBoard);
                    }}
                    key={backBoard.id}
                  >
                    <div className="backboard-heading">
                      <h6 className="backboard-title">{backBoard.title}</h6>
                      <h6 className="backboard-price">{backBoard.price}</h6>
                    </div>
                    <div className="backboard-image-wrapper">
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${backBoard.image}`}
                        alt="backboard"
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}

          {product.backBoardColors.length ? (
            <>
              <div className="title">Backboard Colours</div>
              <div className="backborad-colors-grid">
                {product.backBoardColors.map((backBoardColor) => (
                  <div
                    key={backBoardColor.id}
                    className={`backboard-color ${
                      selectedBacboardColor.id === backBoardColor.id
                        ? "active"
                        : ""
                    }`}
                    onClick={() => {
                      setSelectedBackboardColor(backBoardColor);
                    }}
                  >
                    <div className="backboard-color-info">
                      <p className="backboard-color-title">
                        {backBoardColor.title}
                      </p>
                      <p className="backboard-color-price">
                        {backBoardColor.price}
                      </p>
                    </div>
                    <div
                      className="backboard-color-image"
                      style={{ background: backBoardColor.value }}
                    >
                      {backBoardColor.image && (
                        <img
                          src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${backBoardColor.image}`}
                          alt="Backboard Colour"
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <></>
          )}

          {product.options.length > 0 ? (
            <div className="other-options">
              {product.options.map((option, index) => (
                <div className="option" key={index}>
                  <input
                    onChange={() => {
                      handleOptionChange(option);
                    }}
                    type="checkbox"
                    className="check-input"
                    id={index}
                  />
                  <label htmlFor={index} className="check-label">
                    {option.title}{" "}
                    <span>{option.price ? `£${option.price}` : "Free"}</span>
                  </label>
                </div>
              ))}
            </div>
          ) : (
            <></>
          )}

          <hr />

          <div className="add-to-cart">
            <div className="item-counter">
              <i
                className="fa-solid fa-minus icon"
                onClick={handleMinusClick}
              ></i>
              <input
                type="number"
                onChange={(e) => {
                  handleNumberOfItemsChange(e);
                }}
                value={numberOfItems}
                min={0}
                max={1000}
              />
              <i
                className="fa-solid fa-plus icon"
                onClick={handlePlusClick}
              ></i>
            </div>
            <Button
              className="add-to-cart-button"
              onClick={() => {
                handleAddToCart();
              }}
            >
              Add To Cart
            </Button>
          </div>

          <hr />
          {product.outdoors.length === 0 ? (
            <p className="outdoor-warning">
              This sign is for indoor use only, it is not waterproof. If you
              want a waterproof rated sign you can design an outdoor sign{" "}
              <Link to="/custom-sign-designer">here</Link>
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
      {product.features.length ? <FeatureSwiper Features={product.features}/> : <></>}
    </div>
  );
};

export default ProductInformations;
