import './Button.scss'

const Button = ({children , className ,neon , onClick , type = '' , PD}) => {
  const handleClick = (e)=>{
    // e.preventDefault();
    if(PD)
    {
      e.preventDefault();
    }
    if(onClick){
      onClick();
    }
  }
  return (
    <button type={type} className={`button ${className}`} onClick={(e)=>{handleClick(e) }}>
        {neon && <span></span>}
        {neon && <span></span>}
        {neon && <span></span>}
        {neon && <span></span>}
        {children}
    </button>
  )
}

export default Button