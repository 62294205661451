import { useNavigate, useParams } from "react-router-dom";
import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs"
import { useContext } from "react";
import { ShopContext } from "../Context/ShopContext";
import PageHeader from "../components/PageHeader/PageHeader";
import CategoryBanner from "../components/CategoryBanner/CategoryBanner";
import ProductsFilter from "../components/ProductsFilter/ProductsFilter";

const Category = () => {
    const navigate = useNavigate();
    const {slug} = useParams();
    const { websiteInfos} = useContext(ShopContext);

    const category = websiteInfos.categories.find((category) => category.slug === slug);
    if (!category) {
      // If category is not found, navigate back to the previous page
      navigate('/');
      return null; // You can return null or a loading indicator while navigating
    }
    const breadcrumbs = [
        {label: 'Home' , link: '/'},
        {label: category.title || ''},
    ]
    const filteredProducts = websiteInfos.products.filter(
        (product) => product.category_id === category.id
    );
  return (
    <>
        <CategoryBanner Banner={category.banner} Title={category.title}/>
        <BreadCrumbs breadcrumbs={breadcrumbs}/>
        <PageHeader Title={category.title} Subtitle={category.subtitle} Description={category.description}/>
        <ProductsFilter Products={filteredProducts}/>
    </>
  )
}

export default Category