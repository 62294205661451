import "./CheckoutForm.scss";
import countryList from "react-select-country-list";
import Select from "react-select";
import { useContext, useEffect, useMemo, useState } from "react";
import Button from "../Button/Button";
import { ShopContext } from "../../Context/ShopContext";
// import PaypalIcon from "../../assets/images/paypal-icon.svg";
// import GPay from "../../assets/images/dark_gpay.svg";
import { Controller, useForm } from "react-hook-form";
import useSwalAlert from "../../hooks/useSwalAlert";

const CheckoutForm = () => {
  const [coupon, setCoupon] = useState("");
  const [type, setType] = useState("");
  const [discount, setDiscount] = useState(0);
  const [couponId, setCouponId] = useState(null);
  const [loading, setLoading] = useState(false);
  const showAlert = useSwalAlert();
  const {
    control,
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();
  const countries = useMemo(() => countryList().getData(), []);
  // const [selectedCountry, setSelectedCountry] = useState("");
  const { cartItems } = useContext(ShopContext);
  const totalAmount = cartItems.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);

  const [total, setTotal] = useState(totalAmount);

  const onSubmit = async (formData) => {
    try {
      if (cartItems.length <= 0) {
        showAlert("there is no items in your cart", "", "error");
        return;
      }
      const allData = {
        coupon_id: couponId,
        ...formData,
        cartItems,
        total: total,
      };
      const url = `${process.env.REACT_APP_API_URL}/stripe/checkout`;
      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(allData),
      };

      const response = await fetch(url, options);
      const data = await response.json();
      if (data?.url) {
        window.location.href = data.url;
      } else {
        showAlert("somthing went wrong", "", "error");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleApplyDiscount = async (e) => {
    e.preventDefault();
    try {
      if (!coupon) {
        showAlert("please fill up the field of discount", "", "error");
        return;
      }
      setLoading(true);
      const url = `${process.env.REACT_APP_API_URL}/coupon`;
      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ coupon: coupon }),
      };

      const response = await fetch(url, options);
      const data = await response.json();
      if (data?.data?.code) {
        setType(data?.data?.type);
        setCouponId(data?.data?.id);
        if (data?.data?.type === "percentage") {
          // const amount = totalAmount - (totalAmount * parseFloat(data.data.percentage))/100;
          // setTotal(amount);
          setDiscount(data?.data?.percentage);
        } else {
          setDiscount(data?.data?.value);
          // const amount = totalAmount - parseFloat(data.data.value);
          // setTotal(amount);
        }
      } else {
        showAlert("there is no such a code !!", "", "error");
        setCoupon("");
        setCouponId(null);
        setDiscount(0);
        setTotal(totalAmount);
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (type === "percentage") {
      const amount = totalAmount - (totalAmount * parseFloat(discount)) / 100;
      setTotal(amount);
    } else {
      const amount = totalAmount - parseFloat(discount);
      setTotal(amount);
    }
  }, [type, discount, totalAmount]);

  console.log(cartItems);
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="checkout-form">
        <div className="form-container">
          <div className="form-input">
            <div className="label">Contact</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
              {errors?.email?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
              {errors?.email?.type === "pattern" && (
                <span className="error">Invalid Email.</span>
              )}
            </div>
          </div>
          <div className="form-input">
            <div className="label">Delivery</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Delivery"
                {...register("delivery", {
                  required: true,
                })}
              />
              {errors?.delivery?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
            </div>
          </div>
          <div className="form-input">
            <div className="label">Full Name</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Full Name"
                {...register("fullName", { required: true })}
              />
              {errors?.fullName?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
            </div>
          </div>
          <div className="form-input">
            <div className="label">Company (optional)</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Company"
                {...register("company")}
              />
            </div>
          </div>
          <div className="form-input">
            <div className="label">Address</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Address"
                {...register("address", { required: true })}
              />
              {errors?.address?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
            </div>
          </div>
          <div className="form-input">
            <div className="label">Country</div>
            <div className="input-container select-container">
              <Controller
                name="country"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                    {...field}
                    className="countries-dropdown"
                    options={countries}
                  />
                )}
              />
              {errors?.country?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
            </div>
          </div>
          <div className="form-input">
            <div className="label">State/Province</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="State/Province"
                {...register("state", { required: true })}
              />
              {errors?.state?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
            </div>
          </div>
          <div className="form-input">
            <div className="label">City</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="City"
                {...register("city", { required: true })}
              />
              {errors?.city?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
            </div>
          </div>
          <div className="form-input">
            <div className="label">Zip/Postal Code</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Zip/Postal Code"
                {...register("zip", { required: true })}
              />
              {errors?.zip?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
            </div>
          </div>
          <div className="form-input">
            <div className="label">Phone Number</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Phone Number"
                {...register("phone", {
                  required: true,
                  pattern: /^\+?[1-9]\d{1,14}$/,
                })}
              />
              {errors?.phone?.type === "required" && (
                <span className="error">This field is required.</span>
              )}
              {errors?.phone?.type === "pattern" && (
                <span className="error">Invalid phone number.</span>
              )}
            </div>
          </div>
        </div>

        <div className="pay-container">
          <div className="cart-items">
            {cartItems.map((product) => (
              <div className="cart-item" >
                {product.image ? (
                  <div className="item-image">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${product.image}`}
                      alt={product.title}
                    />
                  </div>
                ) : (
                  <div className="item-image-empty">
                    {/* {product.styles && (
                      <h6
                        className="title"
                        style={product.styles}
                      >
                        {product.text}
                      </h6>
                    )} */}
                    <img src={product.customNeonImage} alt="" />
                  </div>
                )}
                <div className="item-informations">
                  <h5 className="title">{product.title}</h5>
                  <h5 className="price">
                    Price: <span>£{product.price * product.quantity}</span>
                  </h5>
                  <h6 className="size">Size: Small</h6>
                  <h6 className="color">Color: Red</h6>
                  <div className="quantity">
                    <span>Quantity: {product.quantity}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <h5 className="title">Discount Code Or Gift Card</h5>
          <div className="apply-discount">
            <input
              type="text"
              placeholder="Discount Code Or Gift Card"
              className="discount"
              value={coupon}
              onChange={(e) => {
                setCoupon(e.target.value);
              }}
            />
            <button
              onClick={(e) => {
                handleApplyDiscount(e);
              }}
              className="apply-button"
            >
              {loading ? <i className="fa fa-spinner fa-spin"></i> : "Apply"}
            </button>
          </div>
          {couponId && (
            <h5 className="title">
              Discount:{" "}
              {type === "percentage" ? `${discount}%` : `${discount}£`}
            </h5>
          )}
          <h5 className="title">Total: £{parseFloat(total).toFixed(2)}</h5>
          {/* <h5 className="title title-payment">Payment methods:</h5> */}
          {/* <div className="payment-methods">
            <div className="paypal">
              <img src={PaypalIcon} alt="paypal" />
            </div>
            <div className="g-pay">
              <img src={GPay} alt="G Pay" />
            </div>
          </div> */}
          <Button type="submit">
            {isSubmitting ? (
              <i className="fa fa-spinner fa-spin"></i>
            ) : (
              "Checkout"
            )}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
