import { useEffect, useRef } from "react";
import "./Modal.scss";
import classNames from "classnames";
import { motion } from "framer-motion";
import ModalHeader from "./ModalHeader";

const variants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};
const modalVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: { type: "spring", duration: 0.5, bounce: 0.4 },
  },
  exit: { opacity: 0, scale: 0.8, transition: { duration: 0.15 } },
};

const Modal = ({
  children,
  hideHeader,
  className,
  navigateTo,
  closeCallback,
  bodyClassName,
}) => {
  const contentRef = useRef();
  const modalWrapperClassNames = classNames("modal-wrapper", className);
  const modalBodyClassNames = classNames('modal-body',bodyClassName);

  useEffect(() => {
    const contentElement = contentRef.current;
    const hasOverflow =
      contentElement.scrollHeight > contentElement.clientHeight;
    if (hasOverflow) {
      contentElement.style.overflowY = "scroll";
    } else {
      contentElement.style.overflowY = "auto";
    }
  });



  return (
    <motion.div
      className={modalWrapperClassNames}
      variants={variants}
      initial="hidden"
      animate="visible"
      exit="exit"
    >
      <motion.div
        ref={contentRef}
        className="modal"
        variants={modalVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {!hideHeader && (<ModalHeader hideHeader={hideHeader} navigateTo={navigateTo} closeCallback={closeCallback}/>)}

        <div className={modalBodyClassNames}>
            {children}
        </div>
      </motion.div>
    </motion.div>
  );
};

export default Modal;
