import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
import Video from "../assets/images/sinages-video.mp4";
import TempImage from "../assets/images/sky-sign-with-logo.png";
import PageVideoBanner from "../components/PageVideoBanner/PageVideoBanner";
import GetQuoteForm from "../components/GetQuoteForm/GetQuoteForm";

const Signages = () => {
  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Signages" }];
  return (
    <>
      <PageVideoBanner
        Video={Video}
        Image={TempImage}
        Title={"Personalised Neon Lights for you"}
        Description={"Check out our personalised LED neon signs which you can customise with names, initials and your choice of colours. Our range includes trendy name signs for room decor, personalised wedding name signs, cute hearts with your initial, customised birthday party decorations and more."}
      />
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <GetQuoteForm Type="indoor"/>
    </>
  );
};

export default Signages;
