import { useEffect, useRef, useState } from "react";
import "./MainVideoBanner.scss";
import Button from "../Button/Button";
import { useNavigate } from "react-router-dom";

const MainVideoBanner = ({ imageSrc, videoSrc , title , subtitle }) => {
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const videoRef = useRef(null);
  let timeoutId = null;

  const handleLoad = () => {
    timeoutId = setTimeout(() => {
      setIsLoading(false);
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 5000);
  };
  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);

  return (
    <div className="main-video-banner">
      <div className="overlay"></div>
      <video
        ref={videoRef}
        src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${videoSrc[0].download_link}`}
        muted
        loop
        playsInline
        onLoadedData={handleLoad}
      />
      {isLoading && <img src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${imageSrc}`} alt="sky-sign" />}
      <div className="content">
        {/* <p className="description">
          {subtitle}
        </p> */}
        <h3 className="title">
          {title}
        </h3>

        <div className="buttons-group">
          <Button onClick={()=>{navigate('/custom-sign-designer')}} >Design Your Custom Neon</Button>
          <Button onClick={()=>{navigate('/signs-for-sales')}}>Shop</Button>
          <Button onClick={()=>{navigate('/signages')}}>Signages</Button>
        </div>
      </div>
    </div>
  );
};

export default MainVideoBanner;
