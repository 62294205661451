// import { useNavigate } from "react-router-dom"

const ModalHeader = ({hideHeader,navigateTo='/', closeCallback }) => {
    // const navigate = useNavigate();
    const handelModalClose = ()=>{
        // navigate(navigateTo);
        if(closeCallback)
        {
            closeCallback();
        }
    }
  return (
    <div className="modal-header">
        <div className="modal-close" onClick={handelModalClose}>
            <i className="fas fa-times"></i>
        </div>
    </div>
  )
}

export default ModalHeader