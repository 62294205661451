import Draggable from "react-draggable";
import "./SignDesignerComponent.scss";
import { useContext, useEffect, useRef, useState } from "react";
// import Button from '../Button/Button';
import background1 from "../../assets/images/signs-background1.jpeg";
import background2 from "../../assets/images/sign-background2.jpg";
import background3 from "../../assets/images/sign-background3.jpeg";
import background4 from "../../assets/images/sign-background4.jpg";
import background5 from "../../assets/images/sign-background5.jpeg";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCoverflow,
} from "swiper/modules";
import { ShopContext } from "../../Context/ShopContext";
import { v4 } from "uuid";
import useSwalAlert from "../../hooks/useSwalAlert";
import results from "../../data/results.json";
import lengths from "../../data/sizes.json";
import MainLoader from "../MainLoader/MainLoader";
import html2canvas from "html2canvas";

const SignDesignerComponent = () => {
  const neonProperties = {
    NeoTokyo: {
      val: 0.65,
      desk: "73",
      mob: "61",
      fontLineh: "",
      nosize: {},
      lineh: "105",
    },
    Waikiki: {
      val: 0.68,
      desk: "70",
      mob: "50",
      fontLineh: "",
      nosize: {},
      lineh: "87",
    },
    NeonLite: {
      val: 0.68,
      desk: "62",
      mob: "58",
      fontLineh: "",
      nosize: {},
      lineh: "95",
    },
    Typewriter: {
      val: 0.71,
      desk: "70",
      mob: "58",
      fontLineh: "",
      nosize: {},
      lineh: "92",
    },
    Melbourne: {
      val: 0.73,
      desk: "70",
      mob: "62",
      fontLineh: "",
      nosize: {},
      lineh: "100",
    },
    Avante: {
      val: 0.73,
      desk: "75",
      mob: "61",
      fontLineh: "",
      nosize: {},
      lineh: "97",
    },
    Signature: {
      val: 0.74,
      desk: "100",
      mob: "85",
      fontLineh: "",
      nosize: {},
      lineh: "95",
    },
    Beachfront: {
      val: 0.75,
      desk: "73",
      mob: "65",
      fontLineh: "",
      nosize: {},
      lineh: "120",
    },
    Royalty: {
      val: 0.75,
      desk: "68",
      mob: "56",
      fontLineh: "",
      nosize: {},
      lineh: "130",
    },
    Austin: {
      val: 0.77,
      desk: "115",
      mob: "105",
      fontLineh: "",
      nosize: {},
      lineh: "76",
    },
    Neontrace: {
      val: 0.77,
      desk: "62",
      mob: "50",
      fontLineh: "",
      nosize: {},
      lineh: "100",
    },
    Rocket: {
      val: 0.78,
      desk: "50",
      mob: "45",
      fontLineh: "",
      nosize: {},
      lineh: "160",
    },
    ClassicType: {
      val: 0.78,
      desk: "65",
      mob: "55",
      fontLineh: "",
      nosize: {},
      lineh: "99",
    },
    Greenworld: {
      val: 0.79,
      desk: "100",
      mob: "93",
      fontLineh: "",
      nosize: {},
      lineh: "84",
    },
    Monaco: {
      val: 0.79,
      desk: "70",
      mob: "58",
      fontLineh: "",
      nosize: {},
      lineh: "87",
    },
    LoveNote: {
      val: 0.8,
      desk: "70",
      mob: "66",
      fontLineh: "",
      nosize: {},
      lineh: "100",
    },
    Amsterdam: {
      val: 0.81,
      desk: "88",
      mob: "65",
      fontLineh: "",
      nosize: {},
      lineh: "105",
    },
    Buttercup: {
      val: 0.81,
      desk: "120",
      mob: "82",
      fontLineh: "",
      nosize: {},
      lineh: "90",
    },
    Alexa: {
      val: 0.82,
      desk: "80",
      mob: "75",
      fontLineh: "",
      nosize: {},
      lineh: "94",
    },
    Bayview: {
      val: 0.83,
      desk: "105",
      mob: "90",
      fontLineh: "",
      nosize: {},
      lineh: "90",
    },
    NewCursive: {
      val: 0.83,
      desk: "100",
      mob: "91",
      fontLineh: "",
      nosize: {},
      lineh: "87",
    },
    Northshore: {
      val: 0.83,
      desk: "93",
      mob: "83",
      fontLineh: "",
      nosize: {},
      lineh: "115",
    },
    Photogenic: {
      val: 0.84,
      desk: "90",
      mob: "75",
      fontLineh: "",
      nosize: {},
      lineh: "120",
    },
    Sorrento: {
      val: 0.84,
      desk: "70",
      mob: "60",
      fontLineh: "",
      nosize: {},
      lineh: "130",
    },
    Amanda: {
      val: 0.84,
      desk: "80",
      mob: "73",
      fontLineh: "",
      nosize: {},
      lineh: "125",
    },
    Freespirit: {
      val: 0.85,
      desk: "47",
      mob: "49",
      fontLineh: "",
      nosize: {},
      lineh: "180",
    },
    Chelsea: {
      val: 0.86,
      desk: "110",
      mob: "98",
      fontLineh: "",
      nosize: {},
      lineh: "100",
    },
    Barcelona: {
      val: 0.9,
      desk: "70",
      mob: "55",
      fontLineh: "",
      nosize: {},
      lineh: "150",
    },
    Vintage: {
      val: 0.96,
      desk: "75",
      mob: "72",
      fontLineh: "",
      nosize: {},
      lineh: "110",
    },
    WildScript: {
      val: 0.99,
      desk: "105",
      mob: "98",
      fontLineh: "",
      nosize: {},
      lineh: "105",
    },
    Freehand: {
      val: 1.0,
      desk: "90",
      mob: "85",
      fontLineh: "",
      nosize: {},
      lineh: "97",
    },
    Loveneon: {
      val: 1.0,
      desk: "60",
      mob: "54",
      fontLineh: "",
      nosize: {},
      lineh: "95",
    },
    Neonscript: {
      val: 1.01,
      desk: "95",
      mob: "80",
      fontLineh: "",
      nosize: {},
      lineh: "115",
    },
    Venetian: {
      val: 1.07,
      desk: "80",
      mob: "72",
      fontLineh: "",
      nosize: {},
      lineh: "100",
    },
    NeonGlow: {
      val: 1.09,
      desk: "75",
      mob: "60",
      fontLineh: "",
      nosize: {},
      lineh: "100",
    },
    SciFi: {
      val: 1.21,
      desk: "50",
      mob: "45",
      fontLineh: "",
      nosize: {},
      lineh: "108",
    },
    Mayfair: {
      val: 1.24,
      desk: "76",
      mob: "66",
      fontLineh: "",
      nosize: {},
      lineh: "95",
    },
    Nevada: {
      val: 1.31,
      desk: "100",
      mob: "88",
      fontLineh: "",
      nosize: {},
      lineh: "93",
    },
    Bellview: {
      val: 1.34,
      desk: "100",
      mob: "88",
      fontLineh: "",
      nosize: {},
      lineh: "88",
    },
    Marquee: {
      val: 1.7,
      desk: "85",
      mob: "66",
      fontLineh: "",
      nosize: {},
      lineh: "100",
    },
  };
  const showAlert = useSwalAlert();
  const { addToCart, setShowCart, websiteInfos } = useContext(ShopContext);
  const [isLoading , setIsLoading] = useState(true);
  const [badWords , setBadWords] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [totalPrice, setTotalPrice] = useState(0);
  const [textLighted, setTextLighted] = useState(true);
  const [sign, setSign] = useState("");
  const [signBackground, setSignBackground] = useState(background2);
  const [fontsDropDownShow, setFontsDropDownShow] = useState(false);
  const [selectedFont, setSelectedFont] = useState("Photogenic");
  const [selectedFontValue, setSelectedFontValue] = useState(0.84);
  const [selectedFontSize, setSelectedFontSize] = useState(
    screenWidth >= 900
      ? parseInt(neonProperties[selectedFont].desk, 10)
      : parseInt(neonProperties[selectedFont].mob, 10)
  );
  const [fontLineh , setFontLineh] = useState(120);
  const[fontDesk,setFontDesk] = useState(90);
  const[fontMob,setFontMob] = useState(75);
  const [selectedColor, setSelectedColor] = useState({
    name: "Our Primary",
    value: "#740021",
  });
  const [selectedAlign, setSelectedAlign] = useState("left");
  const [signSize, setSignSize] = useState({ width: "", height: "" });
  const [signLettersCount, setSignLettersCount] = useState(0);
  const [selecteSize, setSelectedSize] = useState("Medium");
  const [selectedIndoor, setSelectedIndoor] = useState(
    websiteInfos?.outdoors && websiteInfos.outdoors.length > 0
      ? websiteInfos.outdoors[0]
      : null
  );
  const [selectedBackboard, setSelectedBackboard] = useState(
    websiteInfos?.backboards && websiteInfos.backboards.length > 0
      ? websiteInfos.backboards[0]
      : null
  );
  const [selectedBacboardColor, setSelectedBackboardColor] = useState(
    websiteInfos?.backboard_colors && websiteInfos.backboard_colors.length > 0
      ? websiteInfos.backboard_colors[0]
      : null
  );
  const [showColorsGrid, setShowColorGrid] = useState(false);
  const [options, setOptions] = useState(
    websiteInfos?.options && websiteInfos.options.length > 0
      ? websiteInfos.options
      : []
  );
  const [selectedOptions, setSelectedOptions] = useState([]);
  const signRef = useRef(null);
  const canvasRef = useRef(null);
  const textAreaRef = useRef(null);


  const colors = [
    { name: "Our Primary", value: "#740021" },
    { name: "Warm White", value: "#fffdcf" },
    { name: "White", value: "#e1e3e6" },
    { name: "Lemon Yello", value: "#fff97c" },
    { name: "Golden Yello", value: "#ffd62e" },
    { name: "Light Red", value: "#ff7575" },
    { name: "Red", value: "#ff2a4d" },
    { name: "Coton Candy", value: "#eaa4ff" },
    { name: "Deep Pink", value: "#ff5ce8" },
    { name: "White", value: "#ffffff" },
    { name: "Black", value: "#000000" },
    { name: "Red", value: "#ff0000" },
    { name: "Green", value: "#00ff00" },
    { name: "Blue", value: "#0000ff" },
    { name: "Yellow", value: "#ffff00" },
    { name: "Orange", value: "#ffA500" },
    { name: "Purple", value: "#800080" },
    { name: "Pink", value: "#ffc0cb" },
    { name: "Gray", value: "#808080" },
    { name: "Brown", value: "#a52a2a" },
    { name: "Cyan", value: "#00ffff" },
    // { name: "Magenta", value: "#ff00ff" },
    // { name: "Lime", value: "#00ff00" },
    // { name: "Silver", value: "#c0c0c0" },
    // { name: "Gold", value: "#ffd700" },
    // { name: "Teal", value: "#008080" },
    // { name: "Navy", value: "#000080" },
  ];

  const [currentPrice, setCurrentPrice] = useState(0);

  const generateImage = async ()=>{
    const textElement = textAreaRef.current;
    const canvas = await html2canvas(textElement,{backgroundColor:'black'});
    const imageDataURL = canvas.toDataURL('image/png');
    return imageDataURL;
  }

  const handleTextInputChange = (e) => {
    const input = e.target.value;
    if (input) {
      const letterCount = input.replace(/[\r\n]/g, "").length;
      const lines = sign.split(/[\r\n]+/g).filter((line) => line.trim() !== "");
      const lineCount = lines.length;
      const index = letterCount + lineCount - 2;
      if (index > 49) {
        showAlert(
          "Number of characters is over the limit, Contact us for a qoute",
          "",
          "warning"
        );
        return;
      }
      if (lineCount > 4) {
        showAlert(
          "Number of lines is over the limit, Contact us for a qoute",
          "",
          "warning"
        );
        return;
      }
      if (letterCount > 50) {
        showAlert(
          "Number of characters is over the limit, Contact us for a qoute",
          "",
          "warning"
        );
        return;
      }
    } else {
      setSignLettersCount(0);
    }
    setSign(input);
  };

  const handleAddToCart = async () => {
    if (!sign) {
      showAlert("You must type your own text", "", "error");
      return;
    }

    const lowerCaseSign = sign.toLowerCase();
    const containsBadWord = badWords.some((badWord) => {
      return lowerCaseSign.includes(badWord.word.toLowerCase());
    });

    if (containsBadWord) {
      showAlert("We're having trouble processing your request. Please try rephrasing or contact our support team for assistance.", "", "error");
      return;
    }

    

    const item = {
      type: "sign",
      title: sign,
      text: sign,
      customNeonImage: await generateImage(),
      selectedColor: selectedColor?.name,
      color: selectedColor?.name,
      font: selectedFont || null,
      textAlign: selectedAlign || null,
      selecteSize: selecteSize.title || null,
      size: signSize || null,
      selectedOutdoor: selectedIndoor?.id,
      selectedBacboard: selectedBackboard?.title,
      selectedBacboardColor: selectedBacboardColor?.id,
      price: totalPrice || 0,
      options: selectedOptions.map((option) => option.id),
      quantity: 1,
      itemId: v4(),

      styles: {
        fontFamily: selectedFont,
        textAlign: `${selectedAlign}`,
        color: textLighted ? "#fff" : selectedColor.value,
        textShadow: textLighted
          ? `rgb(255, 255, 255) 0px 0px 5px,
  rgb(255, 255, 255) 0px 0px 10px, ${selectedColor.value} 0px 0px 20px,
  ${selectedColor.value} 0px 0px 30px, ${selectedColor.value} 0px 0px 40px,
  ${selectedColor.value} 0px 0px 55px, ${selectedColor.value} 0px 0px 75px`
          : `${selectedColor.value} 0px 1px 0px, ${selectedColor.value} 0px 2px 0px, ${selectedColor.value} 0px 3px 0px, ${selectedColor.value} 0px 4px 0px, rgba(0, 0, 0, 0.23) 0px 0px 5px, rgba(0, 0, 0, 0.43) 0px 1px 3px, rgba(0, 0, 0, 0.4) 1px 4px 6px, rgba(0, 0, 0, 0.38) 0px 5px 10px, rgba(0, 0, 0, 0.25) 3px 7px 12px`,
      },
    };

    addToCart(item);

    showAlert("Item was added to your cart", "", "", () => {
      setShowCart(true);
    });
  };

  const handleOptionChange = (index) => {
    const updatedOptions = [...options];
    updatedOptions[index].checked =
      updatedOptions[index].checked === "1" ? "0" : "1";
    setOptions(updatedOptions);

    const selectedOptionIds = updatedOptions
      .filter((option) => option.checked === "1")
      .map((option) => option.id);
    setSelectedOptions(selectedOptionIds);
  };

  //effects

  //fetch Bad Words

  useEffect(()=>{
    const fetchContent = async ()=>{
      try{
        const url = `${process.env.REACT_APP_API_URL}/bad-words`;
        const response = await fetch(url);
        const data = await response.json();
        console.log(data);
        setBadWords(data.data);
        setIsLoading(false);
      }catch(error){
        console.error(error);
        setIsLoading(false);
      }
    }
    fetchContent();
  },[])

  //fix text overflow
  useEffect(() => {
    if(!isLoading)
    {
      const containerRect = textAreaRef.current.getBoundingClientRect();
      const textRect = signRef.current.getBoundingClientRect();
      //reduce font size function for fixing
  
      const reduceFontSize = () => {
        const newFontSize = selectedFontSize - 1;
        setSelectedFontSize(newFontSize);
      };
  
      if (textRect.right + 50 >= containerRect.right) {
        reduceFontSize();
      }
      if (sign.length < 4) {
        setSelectedFontSize(
          screenWidth >= 900
            ? fontDesk
            : fontMob
        );
      }
    }
  }, [selectedFontSize, sign, screenWidth, selectedFont,fontDesk,fontMob,isLoading]);

  //screen width watching

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  //calculate price

  useEffect(() => {
    const countPrice = () => {
      const letterCount = sign.replace(/[\r\n]/g, "").length;
      const lines = sign.split(/[\r\n]+/g).filter((line) => line.trim() !== "");
      const lineCount = lines.length;
      if (letterCount > 0) {
        // console.log(selectedFontValue);
        const index = letterCount + lineCount - 2;
        const prc = Math.floor(results[selecteSize][index] * selectedFontValue);
        setCurrentPrice(prc);
      } else {
        setCurrentPrice(0);
      }
    };
    countPrice();
  }, [sign, selecteSize, selectedFontValue]);

  //text mesuring

  useEffect(() => {
    const measureTextSize = () => {
      if (sign) {
        const linesStrings = sign.split(/[\r\n]/);
        const maxLetterCount = Math.max(
          ...linesStrings.map((line) => line.length)
        );
        setSignLettersCount(maxLetterCount);
        const canvas = canvasRef.current;
        const ctx = canvas.getContext("2d");
        const font = `${selectedFontSize}px ${selectedFont}`;
        ctx.font = font;
    //     ctx.textAlign = selectedAlign;
    //     ctx.fillStyle = textLighted ? '#fff' : selectedColor.value;
    // ctx.textShadow = textLighted
    //   ? `rgb(255, 255, 255) 0px 0px 5px, rgb(255, 255, 255) 0px 0px 10px, ${selectedColor.value} 0px 0px 20px, ${selectedColor.value} 0px 0px 30px, ${selectedColor.value} 0px 0px 40px, ${selectedColor.value} 0px 0px 55px, ${selectedColor.value} 0px 0px 75px`
    //   : `${selectedColor.value} 0px 1px 0px, ${selectedColor.value} 0px 2px 0px, ${selectedColor.value} 0px 3px 0px, ${selectedColor.value} 0px 4px 0px, rgba(0, 0, 0, 0.23) 0px 0px 5px, rgba(0, 0, 0, 0.43) 0px 1px 3px, rgba(0, 0, 0, 0.4) 1px 4px 6px, rgba(0, 0, 0, 0.38) 0px 5px 10px, rgba(0, 0, 0, 0.25) 3px 7px 12px`;
    //     ctx.fillText(sign, 0, 0);
        const lines = sign.split("\n");
        let totalWidth = 0;
        let totalHeight = 0;
        // const spacing = selectedFontSize*(parseInt(neonProperties[selectedFont].lineh)/100);
        const lineHeight = selectedFontSize*(fontLineh/100);
        const percentage = (fontLineh/100);
        // let i = 0;
        for (const line of lines) {
          const trimmedLine = line.trim();
          if(trimmedLine !== '')
          {
            const metrics = ctx.measureText(line);
            const lineWidth = metrics.width;
            const segment = metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
            if(totalHeight)
            {
              totalHeight+= ((segment+lineHeight)-(segment*percentage));
            }
            else
            {

              totalHeight += (segment);
            }
          
            totalWidth = Math.max(totalWidth, lineWidth);
            
          }
        }
        const textHeight = totalHeight;
        const textWidth = Math.ceil(totalWidth)+8;
        const cmWidth = lengths[selecteSize][signLettersCount - 1];
        const operator = textWidth / cmWidth;
        const cmHeight = Math.round(textHeight / operator);

        setSignSize({ width: cmWidth, height: cmHeight });
      } 
      else{
        setSignSize({width:'',height:''});
        setSignLettersCount(0);
      }
    };

    measureTextSize();
  }, [sign,selecteSize,selectedFont,selectedFontSize,signLettersCount,fontLineh]);

  //calculate total price

  useEffect(() => {
    let total = parseFloat(currentPrice);

    if (selectedIndoor) {
      total += parseFloat(selectedIndoor.price) || 0;
    }

    if (selectedBackboard) {
      total += parseFloat(selectedBackboard.price) || 0;
    }

    if (selectedBacboardColor) {
      total += parseFloat(selectedBacboardColor.price) || 0;
    }

    options.forEach((option) => {
      if (option.checked === "1") {
        total += parseFloat(option.price) || 0;
      }
    });

    setTotalPrice(total);
  }, [
    options,
    selectedBacboardColor,
    selectedBackboard,
    selectedIndoor,
    currentPrice,
    totalPrice,
  ]);
  if(isLoading)
  {
    return <MainLoader/>
  }
  return (
    <div className="sign-designer-component">
      <div className="content">
        {signBackground && (
          <img
            src={signBackground}
            className="sign-background"
            alt="background"
          />
        )}
        <label className="switch-button">
          <span className="switch">
            <input
              type="checkbox"
              className="switch__input"
              checked={textLighted}
              onChange={(e) => {
                setTextLighted(e.target.checked);
              }}
            />
            <span className="switch__surface">
              <span className="switch__surface-glare"></span>
            </span>
            <span className="switch__inner-shadow"></span>
            <span className="switch__inner">
              <span className="switch__inner-glare"></span>
            </span>
            <span className="switch__rocker-shadow"></span>
            <span className="switch__rocker-sides">
              <span className="switch__rocker-sides-glare"></span>
            </span>
            <span className="switch__rocker">
              <span className="switch__rocker-glare"></span>
            </span>
            <span className="switch__light">
              <span className="switch__light-inner"></span>
            </span>
          </span>
        </label>
        <h5 className="content-price">£{parseFloat(totalPrice).toFixed(2)}</h5>
        <div className="text-area" ref={textAreaRef}>
          <Draggable bounds=".text-area">
            <div className="text-wrapper">
              {sign && (
                <span className="width">
                  <span className="value">
                    {signSize?.width && parseInt(signSize.width) + ` ${"cm"}`}
                  </span>
                </span>
              )}
              {sign && (
                <span className="height">
                  <span className="value">
                    {signSize?.height && parseInt(signSize.height) + ` ${"cm"}`}
                  </span>
                </span>
              )}

              <p
                ref={signRef}
                className={`text ${textLighted ? "lighted" : ""}`}
                style={{
                  fontFamily: selectedFont,
                  fontSize: `${selectedFontSize}px`,
                  lineHeight: `${neonProperties[selectedFont].lineh}%`,
                  textAlign: `${selectedAlign}`,
                  color: textLighted ? "#fff" : selectedColor.value,
                  textShadow: textLighted
                    ? `rgb(255, 255, 255) 0px 0px 5px,
            rgb(255, 255, 255) 0px 0px 10px, ${selectedColor.value} 0px 0px 20px,
            ${selectedColor.value} 0px 0px 30px, ${selectedColor.value} 0px 0px 40px,
            ${selectedColor.value} 0px 0px 55px, ${selectedColor.value} 0px 0px 75px`
                    : `${selectedColor.value} 0px 1px 0px, ${selectedColor.value} 0px 2px 0px, ${selectedColor.value} 0px 3px 0px, ${selectedColor.value} 0px 4px 0px, rgba(0, 0, 0, 0.23) 0px 0px 5px, rgba(0, 0, 0, 0.43) 0px 1px 3px, rgba(0, 0, 0, 0.4) 1px 4px 6px, rgba(0, 0, 0, 0.38) 0px 5px 10px, rgba(0, 0, 0, 0.25) 3px 7px 12px`,
                }}
                dangerouslySetInnerHTML={{
                  __html: sign.replace(/\n/g, "<br />") || "Your Text",
                }}
              ></p>
              <canvas ref={canvasRef} style={{ display: "none" }} />
            </div>
          </Draggable>
        </div>
        <div className="backgrounds-area">
          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
              EffectCoverflow,
            ]}
            autoplay
            loop
            centerInsufficientSlides
            effect="coverflow"
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 5,
              },
            }}
          >
            <SwiperSlide className="slide">
              <div
                className={`image-wrapper ${
                  signBackground === "" ? "active" : ""
                }`}
                onClick={() => {
                  setSignBackground("");
                }}
              ></div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div
                className={`image-wrapper ${
                  signBackground === background1 ? "active" : ""
                }`}
                onClick={() => {
                  setSignBackground(background1);
                }}
              >
                <img src={background1} alt="background" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div
                className={`image-wrapper ${
                  signBackground === background5 ? "active" : ""
                }`}
                onClick={() => {
                  setSignBackground(background5);
                }}
              >
                <img src={background5} alt="background" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div
                className={`image-wrapper ${
                  signBackground === background2 ? "active" : ""
                }`}
                onClick={() => {
                  setSignBackground(background2);
                }}
              >
                <img src={background2} alt="background" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div
                className={`image-wrapper ${
                  signBackground === background3 ? "active" : ""
                }`}
                onClick={() => {
                  setSignBackground(background3);
                }}
              >
                <img src={background3} alt="background" />
              </div>
            </SwiperSlide>
            <SwiperSlide className="slide">
              <div
                className={`image-wrapper ${
                  signBackground === background4 ? "active" : ""
                }`}
                onClick={() => {
                  setSignBackground(background4);
                }}
              >
                <img src={background4} alt="background" />
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
      <div className="editor">
        <div className="scroll-editor">
          <h4 className="title">1- Enter Your Text Here</h4>
          <textarea
            value={sign}
            onChange={(e) => {
              handleTextInputChange(e);
            }}
            className="textarea"
            placeholder="Enter your text here"
          ></textarea>
          <h4 className="title">2- Choose Font</h4>
          <div className="fonts-styles">
            <button
              className="fonts-dropdown-button"
              style={{ fontFamily: selectedFont }}
              onClick={() => {
                setFontsDropDownShow(!fontsDropDownShow);
              }}
            >
              {selectedFont}
              <li
                className={`fas fa-angle-down icon ${
                  fontsDropDownShow ? "fa-flip-vertical" : ""
                }`}
              ></li>
            </button>
            <div
              className={`text-align ${
                selectedAlign === "left" ? "active" : ""
              }`}
              onClick={() => {
                setSelectedAlign("left");
              }}
            >
              <i className="fas fa-align-left"></i>
            </div>
            <div
              className={`text-align ${
                selectedAlign === "center" ? "active" : ""
              }`}
              onClick={() => {
                setSelectedAlign("center");
              }}
            >
              <i className="fas fa-align-center"></i>
            </div>
            <div
              className={`text-align ${
                selectedAlign === "right" ? "active" : ""
              }`}
              onClick={() => {
                setSelectedAlign("right");
              }}
            >
              <i className="fas fa-align-right"></i>
            </div>
          </div>
          <div className={`fonts-grid ${fontsDropDownShow ? "active" : ""}`}>
            {Object.keys(neonProperties).map((font, index) => (
              <div
                className={`font-type ${font === selectedFont ? "active" : ""}`}
                key={index}
                style={{ fontFamily: font }}
                onClick={() => {
                  setSelectedFont(font);
                  setSelectedFontValue(neonProperties[font].val);
                  setSelectedFontSize(
                    screenWidth >= 900
                      ? parseInt(neonProperties[font].desk, 10)
                      : parseInt(neonProperties[font].mob, 10)
                  );
                  setFontLineh(parseInt(neonProperties[font].lineh,10));
                  setFontDesk(parseInt(neonProperties[font].desk,10));
                  setFontMob(parseInt(neonProperties[font].mob,10));
                }}
              >
                {font}
              </div>
            ))}
          </div>
          <p className="warning-alert">*Each sign is handcrafted, and fonts shown will be accurate within a few cm.</p>
          <h4 className="title">3- Choose Colour</h4>
          <button
            className="colors-dropdown-button"
            onClick={() => {
              setShowColorGrid(!showColorsGrid);
            }}
          >
            Choose Colour
            <li
              className={`fas fa-angle-down icon ${
                showColorsGrid ? "fa-flip-vertical" : ""
              }`}
            ></li>
          </button>
          <div className={`color-pick-grid ${showColorsGrid ? "active" : ""}`}>
            {colors.map((color, index) => (
              <div className="color-container">
                <button
                  className="color-pick"
                  key={index}
                  onClick={() => {
                    setSelectedColor(color);
                  }}
                >
                  <span
                    className={`${
                      selectedColor.value === color.value ? "active" : ""
                    }`}
                    style={{ background: color.value }}
                  ></span>
                </button>
                <p>{color.name}</p>
              </div>
            ))}
          </div>
          <h4 className="title">4- Choose Size</h4>
          <div className="sizes-grid">
            {Object.keys(results).map((size, index) => (
              <div
                key={index}
                className={`size-type ${selecteSize === size ? "active" : ""}`}
                onClick={() => {
                  setSelectedSize(size);
                }}
              >
                <h6 className="size-title">{size}</h6>
                {sign && signSize && size === selecteSize && (
                  <p className="size">{`${parseInt(
                    signSize.width
                  )}cm x ${parseInt(signSize.height)}cm`}</p>
                )}
              </div>
            ))}
          </div>
          <p className="warning-alert">*Each sign is handcrafted, and sizes shown will be accurate within a few cm.</p>
          {websiteInfos?.outdoors && websiteInfos.outdoors.length > 0 && (
            <>
              <h4 className="title">Indoor Or Outdoor</h4>
              <div className="indoor-grid">
                {websiteInfos.outdoors.map((outdoor, index) => (
                  <div
                    key={index}
                    className={`door-type ${
                      selectedIndoor?.id === outdoor.id ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedIndoor(outdoor);
                    }}
                  >
                    <h6 className="door-title">{outdoor.title}</h6>
                    <p className="door-price">+ £{outdoor.price}</p>
                  </div>
                ))}
              </div>
            </>
          )}

          {websiteInfos?.backboards && websiteInfos.backboards.length > 0 && (
            <>
              <h4 className="title">5- Backboard Style</h4>
              <div className="backborads-grid">
                {websiteInfos.backboards.map((backboard, index) => (
                  <div
                    key={index}
                    className={`backboard ${
                      selectedBackboard?.id === backboard?.id ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedBackboard(backboard);
                    }}
                  >
                    <div className="backboard-heading">
                      <p className="backboard-title">{backboard.title}</p>
                      <p className="backboard-price">
                        {backboard.price && backboard.price > 0
                          ? backboard.price
                          : "Free"}
                      </p>
                    </div>
                    <div className="backboard-image-wrapper">
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${backboard.image}`}
                        alt={backboard.title}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}

          {websiteInfos?.backboard_colors &&
            websiteInfos.backboard_colors.length > 0 && (
              <>
                <h4 className="title">Backboard Colour</h4>
                <div className="backborad-colors-grid">
                  {/* <div
                    className={`backboard-color ${
                      selectedBacboardColor === -1 ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedBackboardColor(-1);
                    }}
                  >
                    <div className="backboard-color-info">
                      <p className="backboard-color-title">
                        standard clear background
                      </p>
                      <p className="backboard-color-price">Free</p>
                    </div>
                    <div className="backboard-color-image"></div>
                  </div> */}

                  {websiteInfos.backboard_colors.map((color, index) => (
                    <div
                      key={index}
                      className={`backboard-color ${
                        selectedBacboardColor?.id === color?.id ? "active" : ""
                      }`}
                      onClick={() => {
                        setSelectedBackboardColor(color);
                      }}
                    >
                      <div className="backboard-color-info">
                        <p className="backboard-color-title">{color.title}</p>
                        <p className="backboard-color-price">
                          {color.price && color.price > 0
                            ? `+ £${color.price}`
                            : "Free"}
                        </p>
                      </div>
                      <div
                        className="backboard-color-image"
                        style={{ backgroundColor: color.value }}
                      >
                        {color.image && (
                          <img
                            src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${color.image}`}
                            alt={color.title}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}

          {options && options.length > 0 && (
            <>
              <h4 className="title">Other Options</h4>

              <div className="other-options">
                {options.map((option, index) => (
                  <div className="option" key={index}>
                    <input
                      onChange={() => {
                        handleOptionChange(index);
                      }}
                      type="checkbox"
                      className="check-input"
                      id={index}
                      checked={option.checked === "1"}
                    />
                    <label htmlFor={index} className="check-label">
                      {option.title}{" "}
                      <span>{option.price ? `£${option.price}` : "Free"}</span>
                    </label>
                  </div>
                ))}
              </div>
            </>
          )}

          <div className="total">
            <h4>Total</h4>
            {/* <h5 className="old-price">£{parseFloat(totalPrice).toFixed(2)}</h5> */}
            <h5 className="price">£{parseFloat(totalPrice).toFixed(2)}</h5>
          </div>
          <div className="next">
            <button
              className="next-button"
              onClick={() => {
                handleAddToCart();
              }}
            >
              Add To Cart
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignDesignerComponent;
