import Button from "../Button/Button";
import "./MainPageImageBanner.scss";

const MainPageImageBanner = ({ image, title, subtitle, subtitles, action }) => {
  return (
    <div className="main-page-image-banner">
      <div className="overlay"></div>
      {image && (
        <div className="background">
          <img src={image} alt="contact us" />
        </div>
      )}
      <div className="content">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        {title && <h1 className="title">{title}</h1>}
        {subtitle && <p className="subtitle">{subtitle}</p>}
        {subtitles && subtitles?.map((sub,index)=>(<p className="subtitle" key={index}>{sub}</p>))}
      </div>
      {action && <Button className={'action'}>Make Your Custom Signage</Button>}
    </div>
  );
};

export default MainPageImageBanner;
