import "./ProductsFilter.scss";

import { useContext } from "react";
import Product from "../Product/Product";
import { ShopContext } from "../../Context/ShopContext";

const ProductsFilter = ({Products}) => {
  // const [filterValue, setFilterValue] = useState("price");
  // const [filterVisable, setFilterVisable] = useState(false);
  const {websiteInfos} = useContext(ShopContext);
  // let {products} = useContext(ShopContext);
  let products = websiteInfos.products;
  // const [filteredProducts, setFilteredProducts] = useState(products);

  if(Products) products = Products;

  // useEffect(() => {
  //   if (products) {
  //     const sortProductsByPrice = () => {
  //       const sortedProducts = [...products].sort((a, b) => a.price - b.price);
  //       setFilteredProducts(sortedProducts);
  //     };
  //     const sortProductsAlphabetically = () => {
  //       const sortedProducts = [...products].sort((a, b) =>
  //         a.title.localeCompare(b.title)
  //       );
  //       setFilteredProducts(sortedProducts);
  //     };
  //     const sortProductsByDate = () => {
  //       const sortedProducts = [...products].sort((a, b) => {
  //         const dateA = new Date(a.created_at);
  //         const dateB = new Date(b.created_at);
  //         return dateA.getTime() - dateB.getTime();
  //       });
  //       setFilteredProducts(sortedProducts);
  //     };
  //     const sortProductsByFeatured = () => {
  //       const sortedProducts = [...products].sort((a, b) =>
  //         a.featured === b.featured ? 0 : a.featured ? -1 : 1
  //       );
  //       setFilteredProducts(sortedProducts);
  //     };
  //     if (filterValue === "price") {
  //       sortProductsByPrice();
  //     } else if (filterValue === "alphabetically") {
  //       sortProductsAlphabetically();
  //     } else if (filterValue === "date") {
  //       sortProductsByDate();
  //     } else if (filterValue === "featured") {
  //       sortProductsByFeatured();
  //     }
  //   }
  // }, [filterValue, products]);
  return (
    <div className="products-filter">
      <div className="filter-heading">
        {products && (
          <h5 className="products-counter">
            {products.length} Products
          </h5>
        )}
        {/* <div
          className="filter-dropdown"
          onClick={() => {
            setFilterVisable(!filterVisable);
          }}
        >
          <div className="filter-title">{filterValue}</div>
          <li
            className={`fas fa-angle-down icon ${
              filterVisable ? "fa-flip-vertical" : ""
            }`}
          ></li>
          <ul
            className={`dropdown-container ${filterVisable ? "visable" : ""}`}
          >
            <li
              className={`option ${filterValue === "price" ? "active" : ""}`}
              onClick={() => {
                setFilterValue("price");
              }}
            >
              Price
            </li>
            <li
              className={`option ${
                filterValue === "alphabetically" ? "active" : ""
              }`}
              onClick={() => {
                setFilterValue("alphabetically");
              }}
            >
              Alphabetically
            </li>
            <li
              className={`option ${filterValue === "date" ? "active" : ""}`}
              onClick={() => {
                setFilterValue("date");
              }}
            >
              Date
            </li>
            <li
              className={`option ${filterValue === "featured" ? "active" : ""}`}
              onClick={() => {
                setFilterValue("featured");
              }}
            >
              Featured
            </li>
          </ul>
        </div> */}
      </div>

      <div className="products-grid">
        {products.map((product) => (
          <Product product={product} key={product.id} />
        ))}
      </div>
    </div>
  );
};

export default ProductsFilter;
