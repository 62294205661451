import "./PageHeader.scss";

const PageHeader = ({ Title, Subtitle, Description , Center }) => {
  return (
    <div className="page-header" style={{textAlign: Center === true ? 'center' : 'start'}}>
      {Title && <h3 className="title">{Title}</h3>}
      {Subtitle && <h2 className="subtitle">{Subtitle}</h2>}
      {Description && <p className="description">{Description}</p>}
    </div>
  );
};

export default PageHeader;
