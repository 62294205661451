import "./Footer.scss";
// import LogoWhite from '../../assets/images/logo-white.svg';
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/instagram.svg";
import { ReactComponent as TikTokIcon } from "../../assets/images/tiktok.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/youtube.svg";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { ShopContext } from "../../Context/ShopContext";

const Footer = () => {
  const { websiteInfos } = useContext(ShopContext);
  const googleMapsURL =
    "https://www.google.com/maps/place/Sky+Signs+%26+Graphic+Design/@53.5893855,-2.4413219,20.2z/data=!3m1!5s0x487ba7058b4cf04b:0x2c18a1a6a6a65c48!4m6!3m5!1s0x487ba7c3ea753dd1:0xbec2cb04d10bf0bb!8m2!3d53.5892703!4d-2.4409406!16s%2Fg%2F11kk66tq_g?entry=ttu";
  // const navigate = useNavigate();
  return (
    <div className="footer">
      {/* <div className="brand"> */}
      {/* <h3>Neon Shop</h3> */}
      {/* <img src={LogoWhite} alt="Sky Signs" onClick={()=>{navigate('')}} /> */}
      {/* </div> */}
      {/* <div className="infos">
        <h6 className="title">NEON SHOP SIGNS</h6>
        <Link to='/custom-sign-designer' className="link">
          Custom Neon Shop Builder
        </Link>
      </div> */}
      {/* <div className="infos">
        <h6 className="title">CUSTOMER CARE</h6>
        <Link to="/faq" className="link">
          FAQ
        </Link>
        <Link to="/contact-us" className="link">
          Contact Us
        </Link>
        <Link to="/privacy-policy" className="link">
          Our Privacy Ploicy
        </Link>
      </div> */}
      {websiteInfos?.address && (
        <div className="infos">
          <h3 className="title">Address</h3>
          <a
            className="link"
            href={googleMapsURL}
            target="_blank"
            rel="noopener noreferrer"
          >
            {websiteInfos.address}
          </a>
        </div>
      )}
      <div className="infos">
        <h6 className="title">SOCIAL</h6>
        {/* <Link to="/faq" className="link">
          FAQ
        </Link>
        <Link to="/privacy-policy" className="link">
          Our Privacy Ploicy
        </Link> */}
        <p className="subtitle">Find us on Instagram and Facebook</p>
        {/* {websiteInfos.news && (
          <a href={websiteInfos.news} className="link">
            News
          </a>
        )} */}
        <div className="icons">
          {websiteInfos.facebook && (
            <a href={websiteInfos.facebook}>
              <FacebookIcon />
            </a>
          )}
          {websiteInfos.instagram && (
            <a href={websiteInfos.instagram}>
              <InstagramIcon />
            </a>
          )}
          {websiteInfos.tiktok && (
            <a href={websiteInfos.tiktok}>
              <TikTokIcon />
            </a>
          )}
          {websiteInfos.youtube && (
            <a href={websiteInfos.youtube}>
              <YoutubeIcon />
            </a>
          )}
        </div>
        <Link to="/contact-us" className="link">
          Contact Us
        </Link>
      </div>
      <div className="infos">
        <h3 className="title">CONTACT US</h3>
        {websiteInfos.email && (
          <>
            <p className="subtitle">Email:</p>
            <a href={`mailto:${websiteInfos.email}`} className="link">
              {websiteInfos.email}
            </a>
          </>
        )}
        {websiteInfos.phone && (
          <>
            <p className="subtitle">Phone:</p>
            <a href={`tel:${websiteInfos.phone}`} className="link">
              {websiteInfos.phone}
            </a>
          </>
        )}
        {/* {websiteInfos.address && (
          <>
            <p className="subtitle">Address:</p>
            <a className="link" href={googleMapsURL} target="_blank" rel="noopener noreferrer">
              {websiteInfos.address}
            </a>
          </>
        )} */}
      </div>
    </div>
  );
};

export default Footer;
