import './MainLoader.scss'

const MainLoader = () => {
  return (
    <div className="main-loader">
      <div className="spinner"></div>
    </div>
  )
}

export default MainLoader