import { useEffect, useRef, useState } from "react";
import "./PageVideoBanner.scss";

const PageVideoBanner = ({ Video, Image, Title, Description }) => {
  const [isLoading, setIsLoading] = useState(true);
  const videoRef = useRef(null);
  let timeoutId = null;
  const handleLoad = () => {
    timeoutId = setTimeout(() => {
      setIsLoading(false);
      if (videoRef.current) {
        videoRef.current.play();
      }
    }, 5000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, [timeoutId]);
  return (
    <div className="page-video-banner">
      <div className="overlay"></div>
      <video
        src={Video}
        ref={videoRef}
        muted
        loop
        playsInline
        onLoadedData={handleLoad}
      />
      {isLoading && <img src={Image} alt="sky-sign" />}
      <div className="content">
        {Title && <h1 className="title">{Title}</h1>}
        {Description && <p className="description">{Description}</p>}
      </div>
    </div>
  );
};

export default PageVideoBanner;
