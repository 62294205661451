import "./ContactInformations.scss";

import { ReactComponent as AddressIcon } from "../../assets/images/address.svg";
import { ReactComponent as PhoneIcon } from "../../assets/images/phone.svg";
import { ReactComponent as EmailIcon } from "../../assets/images/email.svg";
import { ReactComponent as FacebookIcon } from "../../assets/images/facebook.svg";
import { ReactComponent as InstagramIcon } from "../../assets/images/instagram.svg";
import { ReactComponent as TikTokIcon } from "../../assets/images/tiktok.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/images/youtube.svg";

import { useContext } from "react";
import { ShopContext } from "../../Context/ShopContext";

const ContactInformations = () => {
  const { websiteInfos } = useContext(ShopContext);
  return (
    <div className="contact-informations">
      <h3 className="head">Get In Touch</h3>
      <div className="info">
        <div className="col1">
          <AddressIcon className="icon" />
          <h6 className="title">Address</h6>
        </div>
        <div className="col2">
          <p>{websiteInfos?.address}</p>
        </div>
      </div>
      <div className="info">
        <div className="col1">
          <PhoneIcon className="icon" />
          <h6 className="title">Phone</h6>
        </div>
        <div className="col2">
          <p>
            <a href={`tel:${websiteInfos?.phone}`}>{websiteInfos?.phone}</a>
          </p>
        </div>
      </div>
      <div className="info">
        <div className="col1">
          <EmailIcon className="icon" />
          <h6 className="title">Email</h6>
        </div>
        <div className="col2">
          <p>
            <a href={`mailto:${websiteInfos?.email}`}>{websiteInfos?.email}</a>
          </p>
        </div>
      </div>
      <div className="social-media">
        {websiteInfos.facebook && (
          <a href={websiteInfos.facebook}>
            <FacebookIcon className="icon" />
          </a>
        )}
        {websiteInfos.instagram && (
          <a href={websiteInfos.instagram}>
            <InstagramIcon className="icon" />
          </a>
        )}
        {websiteInfos.tiktok && (
          <a href={websiteInfos.tiktok}>
            <TikTokIcon className="icon" />
          </a>
        )}
        {websiteInfos.youtube && (
          <a href={websiteInfos.youtube}>
            <YoutubeIcon className="icon" />
          </a>
        )}
      </div>
    </div>
  );
};

export default ContactInformations;
