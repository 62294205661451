import { Outlet } from "react-router-dom"
// import AnnouncmentBar from "../AnnouncmentBar/AnnouncmentBar"
import Navbar from "../Navbar/Navbar"
import Footer from "../Footer/Footer"
import Cart from "../Cart/Cart"
import { useContext } from "react"
import { ShopContext } from "../../Context/ShopContext"
import ScrollToTop from "../../hooks/ScrollToTop"
// import MailingSubscription from "../MailingSubscription/MailingSubscription"
import AnnouncmentBarSwiper from "../AnnouncmentBarSwiper/AnnouncmentBarSwiper"
import WhatsappConnect from "../WhatsappConnect/WhatsappConnect"
import Modal from "../Modal/Modal"
import ContactInformations from "../ContactInformations/ContactInformations"

const Layout = () => {
    const {showCart,showContactModal,setShowContactModal} = useContext(ShopContext);
    const handleModalClose = ()=>{
      setShowContactModal(false);
    }
  return (
    <>
    {showCart && <Cart/>}
    {showContactModal && (
      <Modal closeCallback={handleModalClose}>
        <ContactInformations/>
      </Modal>)}
    {/* <AnnouncmentBar/> */}
    <AnnouncmentBarSwiper/>
    <Navbar/>
    <ScrollToTop/>
    {/* <MailingSubscription/> */}
    <WhatsappConnect/>
    <Outlet/>
    <Footer/>
    </>
  )
}

export default Layout