import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
import PageHeader from "../components/PageHeader/PageHeader";
import GetQuoteBackground from "../assets/images/business.avif";
import MainPageImageBanner from "../components/MainPageImageBanner/MainPageImageBanner";
import GetQuoteForm from "../components/GetQuoteForm/GetQuoteForm";


const BusinessLogo = () => {
  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Business Signs & Logos" },
  ];
  return (
    <>
      <MainPageImageBanner image={GetQuoteBackground} title={'Business Logo'}/>
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <PageHeader
        Center
        Title={"Upload your Logo or Image for a Free Quote"}
        Subtitle={
          "TOP QUALITY CUSTOM SIGNAGE PRODUCTS & WHOLESALE SOLUTIONS AT COMPETITIVE PRICES"
        }
      />
      <PageHeader Center Title={'Get Your Free Quote'}/>
      <GetQuoteForm Type="bussiness"/>
    </>
  );
};

export default BusinessLogo;
