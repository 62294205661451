
const allProdcts = [
    {
        id: 1,
        category_id:1,
        category: "Category 1",
        title: "Take it easy led neon sign",
        description: "Description for Product 1 Lorem ipsum dolor sit amet, consectetur adipisicing elit. Labore accusamus natus quibusdam nulla sed omnis nobis sint dolorem libero ex amet animi earum architecto necessitatibus, beatae nesciunt dolor fugit ipsa.",
        oldPrice: 19.99,
        price: 14.99,
        image: 'images/take_it_easy.webp',
        categoryId: 1,
        date: new Date("2022-01-01"),
        featured: true,
        sizes: [{ title: 'Small', value: '52cm x 48cm', price: 223 }, { title: 'Medium', value: '90cm x 82cm', price: 385 }, { title: 'Large', value: '124cm x 113cm', price: 532 }],
        slug: 'product1',
        colors: [{ title: 'orange', file: 'orange-color.png', default: true }, { title: 'lemonyellow', file: 'lemonyellow-color.png', default: false }, { title: 'deepgreen', file: 'deepgreen-color.png', default: false }, { title: 'red', file: 'red-color.png', default: false }],
        options: [{ id:1 , title: 'Special Offer: Remote and Dimmer', price: 0 }, {id:2 , title: 'Neon Sign Wall Mounting Kit', price: 12 }, {id:3 , title: 'Neon Sign Hanging Wire Kit', price: 12 }],
        outdoor: false,
    },
    {
        id: 2,
        category_id:2,
        category: "Category 2",
        title: "Product 2",
        description: "Description for Product 2",
        oldPrice: 29.99,
        price: 24.99,
        image: 'images/kiss.webp',
        categoryId: 1,
        date: new Date("2023-01-01"),
        featured: false,
        sizes: [{ title: 'Small', value: '52cm x 48cm', price: 223 }, { title: 'Medium', value: '90cm x 82cm', price: 385 }, { title: 'Large', value: '124cm x 113cm', price: 532 }],
        slug: 'product2',
    },
    {
        id: 3,
        category_id:2,
        category: "Category 2",
        title: "Planet",
        description: "the description of the product comes here",
        price: 111.99,
        image: 'images/smoke.webp',
        images: ['images/planet.webp', 'images/planet.webp'],
        categoryId: 1,
        date: new Date("2024-01-01"),
        featured: true,
        sizes: [{ title: 'Small', value: '52cm x 48cm', price: 223 }, { title: 'Medium', value: '90cm x 82cm', price: 385 }, { title: 'Large', value: '124cm x 113cm', price: 532 }],
        slug: 'product3'
    },
    {
        id:4,
        category_id:1,
        category: "Category 1",
        title: "Smoking Neon",
        description: "one of our Neon art for somkers",
        price: 24.99,
        image: 'images/planet.webp',
        categoryId: 1,
        date: new Date("2022-01-10"),
        featured: false,
        sizes: [{ title: 'Small', value: '52cm x 48cm', price: 223 }, { title: 'Medium', value: '90cm x 82cm', price: 385 }, { title: 'Large', value: '124cm x 113cm', price: 532 }],
        slug: 'product4'
    },
]

export default allProdcts;