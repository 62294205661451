import './FeatureSwiper.scss'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import {ReactComponent as NextIcon} from '../../assets/images/navigate-next.svg';
import {ReactComponent as PrevIcon} from '../../assets/images/navigate-prev.svg';

const FeatureSwiper = ({Features}) => {
    if (Features.length === 0) {
        return null;
      }
  return (
    <div className='feature-swiper'>
        <Swiper
            slidesPerView={1}
            centerInsufficientSlides
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            navigation={{
                nextEl:'.arrow-next',
                prevEl:'.arrow-prev'
            }}
            loop
            breakpoints={{
                768: {
                  slidesPerView: 3,
                },
                1024:{
                    slidesPerView: 4,
                }
            }}
        >
            {Features.map((feature)=>(
                <SwiperSlide key={feature.id}>
                <div className="feature">
                    <div className="icon">
                        <img src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${feature.icon}`} alt="badge" />
                    </div>
                    <h6 className='title'>
                        {feature.title}
                    </h6>
                    <p className='subtitle'>
                        {feature.subtitle}
                    </p>
                </div>
            </SwiperSlide>
            ))}
            
            {/* <SwiperSlide>
                <div className="feature">
                    <div className="icon">
                        <img src={FreeIcon} alt="free shiping" />
                    </div>
                    <h6 className='title'>
                        FREE SHIPPING WITH TRACKING
                    </h6>
                    <p className='subtitle'>
                        We are proud to offer FREE, secure shipping across our country.
                    </p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="feature">
                    <div className="icon">
                        <img src={HandIcon} alt="hand made" />
                    </div>
                    <h6 className='title'>
                        HAND-MADE
                    </h6>
                    <p className='subtitle'>
                        It was crafted with the highest quality materials.
                    </p>
                </div>
            </SwiperSlide>
            <SwiperSlide>
                <div className="feature">
                    <div className="icon">
                        <img src={LoveIcon} alt="hand made" />
                    </div>
                    <h6 className='title'>
                        MADE WITH LOVE
                    </h6>
                    <p className='subtitle'>
                        It was checked twice by our neon specialists before we deliver it to your doorstep.
                    </p>
                </div>
            </SwiperSlide> */}
        </Swiper>
        <div className="arrows">
            <div className="arrow-prev">
              <PrevIcon/>
            </div>
            <div className="arrow-next">
              <NextIcon/>
            </div>
        </div>
    </div>
  )
}

export default FeatureSwiper