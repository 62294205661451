import { createContext, useEffect, useState } from "react";
import products from "../data/allProducts";
import colors from "../data/colors";
import categories from "../data/categories";
import MainLoader from "../components/MainLoader/MainLoader";
export const ShopContext = createContext(null);

const ShopContextProvider = ({ children }) => {
  const [showCart, setShowCart] = useState(false);
  const [showContactModal , setShowContactModal] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const cartItemsCount = cartItems.length;
  const [isLoading , setIsLoading] = useState(true);
  const [websiteInfos , setWebsiteInfos] = useState(null);

  useEffect(() => {
    // Retrieve cart data from localStorage when the component mounts
    const storedCartItems = localStorage.getItem("cartItems");
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/website-content`;
        const response = await fetch(url);
        const data = await response.json();
        setWebsiteInfos(data.data);
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };

    fetchContent();
  }, []);

  const addToCart = (item) => {
    const updatedCartItems = [...cartItems, item];
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  const removeFromCart = (item) => {
    const updatedCartItems = cartItems.filter(
      (cartItem) => cartItem.itemId !== item.itemId
    );
    setCartItems(updatedCartItems);
    localStorage.setItem("cartItems", JSON.stringify(updatedCartItems));
  };

  const clearCart = () =>{
    setCartItems([]);
    localStorage.removeItem("cartItems");
  }
  const contextValue = {
    products,
    categories,
    colors,
    showCart,
    setShowCart,
    addToCart,
    removeFromCart,
    clearCart,
    cartItems,
    setCartItems,
    cartItemsCount,
    websiteInfos,
    showContactModal,
    setShowContactModal
  };

  if(isLoading)
  {
    return (
      <MainLoader/>
    )
  }

  return (
    <ShopContext.Provider value={contextValue}>{children}</ShopContext.Provider>
  );
};
export default ShopContextProvider;
