import MainVideoBanner from "../components/MainVideoBanner/MainVideoBanner";
import Heading from "../components/Heading/Heading";
import GridBanner from "../components/GridBanner/GridBanner";
import FeatureSwiper from "../components/FeatureSwiper/FeatureSwiper";
import WhyChooseUs from "../components/WhyChooseUs/WhyChooseUs";
// import ImagesGrid from "../components/ImagesGrid/ImagesGrid";
import FeaturedProducts from "../components/FeaturedProducts/FeaturedProducts";
// import BrandsSwiper from "../components/BrandsSwiper/BrandsSwiper";
// import InstaPosts from "../components/InstaPosts/InstaPosts";
// import AboutUsRing from "../components/AboutUsRing/AboutUsRing";
// import ImagesSwiper from "../components/ImagesSwiper/ImagesSwiper";
import { useContext, useEffect, useState } from "react";
import MainLoader from "../components/MainLoader/MainLoader";
import { useLocation } from "react-router-dom";
import { ShopContext } from "../Context/ShopContext";

const Home = () => {
  const [isLoading , setIsLoading] = useState(true);
  const [fetchedData , setFetchedData] = useState(null);
  const {setCartItems} = useContext(ShopContext);
  const location = useLocation();
  useEffect(() => {
    const fetchContent = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/home-content`;
        const response = await fetch(url);
        const data = await response.json();
        setIsLoading(false);
        setFetchedData(data.data);
        
      } catch (error) {
        console.error(error);
      }
    };

    fetchContent();
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const success = queryParams.get('success');

    if (success === 'true') {
      // Payment success message
      alert('Payment successful!');
      setCartItems([]);
      localStorage.removeItem("cartItems");
    } else if (success === 'false'){
      alert('Payment Unsuccessful');
    }
  }, [location.search,setCartItems]);


  if (isLoading) {
    return <MainLoader/>;
  }
  return (
    <>
      <MainVideoBanner imageSrc={fetchedData.main_banner_image} videoSrc={JSON.parse(fetchedData.main_banner_video)} title={fetchedData.main_banner_title} subtitle={fetchedData.main_banner_subtitle} />
      <Heading
        title={fetchedData.three_banners_title}
        subTitle={fetchedData.three_banners_subtitle}
      />
      <GridBanner firstImage={fetchedData.three_banners_second_image} secondImage={fetchedData.three_banners_third_image} />
      <FeatureSwiper Features={fetchedData.features}  />
      {/* <AboutUsRing Items={fetchedData.info_rings} /> */}
      {/* <ImagesSwiper BackImage={fetchedData.light_swiper_image_back} FrontImage={fetchedData.light_swiper_image_front} /> */}
      <Heading title="Why Choose Us ?" />
      <WhyChooseUs Options={fetchedData.why_choose_us} />
      {/* <ImagesGrid Images={fetchedData.home_grid_images} /> */}
      <Heading
        title="Best Sellers"
        subTitle="Best Sellers from our range of LED neon lights and wall art"
      />
      <FeaturedProducts />
      <Heading title="Our Services" />
      {fetchedData?.services && <WhyChooseUs Options={fetchedData.services} />}
      {/* <Heading title="Brands Trusted Us" />
      <BrandsSwiper Brands={fetchedData.brands} /> */}
      {/* <Heading
        title="Our Community On IG!"
        subTitle="@instagram user comes here"
      />
      <InstaPosts Posts={fetchedData.communities} /> */}
    </>
  );
};

export default Home;
