import SignDesignerComponent from "../components/SignDesignerComponent/SignDesignerComponent"

const SignDesigner = () => {
  return (
    <>
        <SignDesignerComponent/>
    </>
  )
}

export default SignDesigner