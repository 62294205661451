import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs"
import PageHeader from "../components/PageHeader/PageHeader"
import PageVideoBanner from "../components/PageVideoBanner/PageVideoBanner"
import ProductsFilter from "../components/ProductsFilter/ProductsFilter"

import Video from '../assets/images/shop-video.mp4';
import TempImage from '../assets/images/sky-sign-with-logo.png';

const Shop = () => {
    const breadcrumbs = [
        {label: 'Home' , link: '/'},
        {label: 'Shop'},
    ]
  return (
    <>
        <PageVideoBanner Video={Video} Image={TempImage} Title={'Shop'}/>
        <BreadCrumbs breadcrumbs={breadcrumbs}/>
        <PageHeader Title={'LED Neon Signs for Sale'} Subtitle={'Buy Neon Signs Here'} Description={'Our range of stunning LED neon signs offer something for everyone. Artistic, aesthetic, cool, cute, hipster, formal, inspirational, sexy – whatever you’re after we’ve either got it in our online shop or we can make it for you.'}/>
        <ProductsFilter/>
    </>
  )
}

export default Shop