import "./Navbar.scss";
import Logo from "../../assets/images/logo.svg";
import { ReactComponent as ContactIcon } from "../../assets/images/icons8-contacts.svg";
import { ReactComponent as CartIcon } from "../../assets/images/bag.svg";
import { ReactComponent as ListIcon } from "../../assets/images/list.svg";
import { ReactComponent as CloseIcon } from "../../assets/images/x-circle.svg";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ShopContext } from "../../Context/ShopContext";

const Navbar = () => {
  const location = useLocation();
  const [toggleNavbar, setToggleNavbar] = useState(false);
  const {
    // websiteInfos,
    showCart,
    setShowCart,
    cartItemsCount,
    setShowContactModal,
  } = useContext(ShopContext);
  // const [searchQuery, setSearchQuery] = useState("");
  // const [showSearch, setShowSearch] = useState(false);
  // const [showCategories, setShowCategories] = useState(false);
  const [showSignages, setShowSignages] = useState(false);
  const navigate = useNavigate();

  // const products = websiteInfos.products;

  const handleToggleNavbar = () => {
    setToggleNavbar(!toggleNavbar);
  };

  // const filteredProducts = products.filter((product) =>
  //   product.title.toLowerCase().includes(searchQuery.toLowerCase())
  // );

  // const handleInputChange = (event) => {
  //   setSearchQuery(event.target.value);
  // };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        // setShowSearch(false);
        // setShowCategories(false);
        setShowSignages(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="navbar">
      <div
        className="navbar-brand"
        onClick={() => {
          navigate("/");
        }}
      >
        {/* <h3>Neon Shop</h3> */}
        <img src={Logo} alt="Sky Signs" />
      </div>
      <div className={`navbar-list-wrapper ${toggleNavbar ? "show" : ""}`}>
        <span className="navbar-overlay" onClick={handleToggleNavbar}></span>
        <ul className="navbar-list">
          <CloseIcon className="close" onClick={handleToggleNavbar} />
          <li>
            <Link
              onClick={() => {
                setToggleNavbar(false);
              }}
              to="/"
              className={location.pathname === "/" ? "active" : ""}
            >
              home
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setToggleNavbar(false);
              }}
              to="/custom-sign-designer"
              className={
                location.pathname === "/custom-sign-designer" ? "active" : ""
              }
            >
              Design your custom neon
            </Link>
          </li>
          <li className="shop">
            <Link
              onClick={() => {
                setToggleNavbar(false);
              }}
              to="/signs-for-sales"
              className={
                location.pathname === "/signs-for-sales" ? "active" : ""
              }
            >
              shop
            </Link>
            {/* <span
              onClick={() => {
                setShowCategories(!showCategories);
                setShowSignages(false);
                // setShowSearch(false);
                // setToggleNavbar(false);
              }}
              className={`fas fa-angle-down ${
                showCategories ? "fa-flip-vertical" : ""
              }`}
            ></span> */}
          </li>
          {/* <div className={`categories ${showCategories ? "show" : ""}`}>
            {categories.map((category, index) => (
              <div
                className="category"
                onClick={() => {
                  navigate(`/category/${category.slug}`);
                  setShowCategories(false);
                  setToggleNavbar(false);
                }}
                key={index}
              >
                {category.title}
              </div>
            ))}
          </div> */}
          {/* {websiteInfos.categories.length ? (
            <div className={`categories ${showCategories ? "show" : ""}`}>
              {websiteInfos.categories.map((category) => (
                <div
                  className="category"
                  onClick={() => {
                    navigate(`/category/${category.slug}`);
                    setShowCategories(false);
                    setToggleNavbar(false);
                  }}
                  key={category.id}
                >
                  {category.title}
                </div>
              ))}
            </div>
          ) : (
            <></>
          )} */}
          {/* <li>
            <Link
              onClick={() => {
                setToggleNavbar(false);
              }}
              to="/get-quote"
              className={location.pathname === "/get-quote" ? "active" : ""}
            >
              get a quote
            </Link>
          </li> */}
          <li className="shop">
            <Link
              onClick={() => {
                setToggleNavbar(false);
              }}
              to="/signages"
              className={location.pathname === "/signages" ? "active" : ""}
            >
              signages
            </Link>
            <span
              onClick={() => {
                // setShowCategories(false);
                setShowSignages(!showSignages);
              }}
              className={`fas fa-angle-down ${
                showSignages ? "fa-flip-vertical" : ""
              }`}
            ></span>
          </li>
          <div className={`categories ${showSignages ? "show" : ""}`}>
            <div
              className="category"
              onClick={() => {
                navigate("/get-quote");
                // setShowCategories(false);
                setShowSignages(false);
                setToggleNavbar(false);
              }}
            >
              Indoor
            </div>
            <div
              className="category"
              onClick={() => {
                navigate("/outdoor-signs");
                // setShowCategories(false);
                setShowSignages(false);
                setToggleNavbar(false);
              }}
            >
              Outdoor
            </div>
            <div
              className="category"
              onClick={() => {
                navigate("/business-logo");
                // setShowCategories(false);
                setShowSignages(false);
                setToggleNavbar(false);
              }}
            >
              Business Logo
            </div>
          </div>

          {/* <li>
            <Link
              onClick={() => {
                setToggleNavbar(false);
              }}
              to="/business-logo"
              className={location.pathname === "/business-logo" ? "active" : ""}
            >
              business logo
            </Link>
          </li>
          <li>
            <Link
              onClick={() => {
                setToggleNavbar(false);
              }}
              to="/outdoor-signs"
              className={location.pathname === "/outdoor-signs" ? "active" : ""}
            >
              outdoor signs
            </Link>
          </li> */}
        </ul>
      </div>

      <div className="navbar-icons">
        <div className="col1">
          <div className="icon contact">
            <ContactIcon
              onClick={() => {
                setShowContactModal(true);
              }}
            />
          </div>
          <div className="icon icon-cart">
            <CartIcon
              onClick={() => {
                setShowCart(!showCart);
              }}
            />
            <span className="counter">{cartItemsCount}</span>
          </div>
        </div>

        <ListIcon className="navbar-list-icon" onClick={handleToggleNavbar} />
      </div>
    </div>
  );
};

export default Navbar;
