import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs"
import CheckoutForm from "../components/CheckoutForm/CheckoutForm"


const Checkout = () => {
    const breadcrumbs = [
        {label: 'Home' , link: '/'},
        {label: 'Check Out'},
    ]
  return (
    <>
        <BreadCrumbs breadcrumbs={breadcrumbs}/>
        <CheckoutForm/>
    </>
  )
}

export default Checkout