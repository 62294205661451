import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
// import GetQuoteForm from "../components/GetQuoteForm/GetQuoteForm";
import PageHeader from "../components/PageHeader/PageHeader";
import Video from "../assets/images/sinages-video.mp4";
import TempImage from "../assets/images/sky-sign-with-logo.png";
import PageVideoBanner from "../components/PageVideoBanner/PageVideoBanner";
import { useEffect, useState } from "react";
import MainLoader from "../components/MainLoader/MainLoader";
import SignagesContainer from "../components/SignagesContainer/SignagesContainer";

const GetQuote = () => {
  const [signages, setSignages] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/signage`;
        const response = await fetch(url);
        const data = await response.json();
        setSignages(data.data);
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    fetchContent();
  }, []);

  const breadcrumbs = [{ label: "Home", link: "/" }, { label: "Indoor Signs" }];
  if(isLoading){
    return <MainLoader/>;
  }
  return (
    <>
      <PageVideoBanner Video={Video} Image={TempImage} Title={"Indoor Signs"} />
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      {/* <PageHeader
        Center
        Title={"Turn Your Image into Neon Sign"}
        Subtitle={
          "Custom Neon has a fantastic team of LED neon designers that can take your ideas and turn them into eye-catching business logos, light-up wall art, games room signs, personalized bar lights, wedding decor, neon party lights and more!"
        }
      /> */}
      <PageHeader Center Title={"Get Your Indoor Signs"} />
      <SignagesContainer Signages={signages}/>
      {/* <GetQuoteForm /> */}
    </>
  );
};

export default GetQuote;
