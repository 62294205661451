import "./AnnouncmentBarSwiper.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
} from "swiper/modules";
import { useContext } from "react";
import { ShopContext } from "../../Context/ShopContext";

const AnnouncmentBarSwiper = () => {
  const { websiteInfos } = useContext(ShopContext);
  return (
    <div className="announcment-bar-swiper">
      <Swiper
        modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
        loop
        centerInsufficientSlides
        slidesPerView={1}
        autoplay
      >
        {websiteInfos?.announcements?.map((announcement, index) => (
          <SwiperSlide key={index}>
            <div className="announcment">{announcement?.message}</div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default AnnouncmentBarSwiper;
