import './Product.scss'
import Button from '../Button/Button'
import { useNavigate } from 'react-router-dom'

const Product = ({product , FromHome}) => {
  const navigate = useNavigate();
  const showProductDetails = ()=>{
    if(FromHome)
    {
      navigate('/signs-for-sales');
    }
    else
    {
      navigate(`/product/${product.slug}`);
    }
  }
  return (
    <div className='product' onClick={()=>{showProductDetails();}}>
      <div className="main-image-wrapper">
        <img src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${product.image}`} alt="image1" />
        <Button className="shop-button neon sunny" onClick={()=>{showProductDetails();}}>
          {FromHome ? 'Shop' : 'Details'}
        </Button>
      </div>
      <h5 className="title">
        {product.title}
      </h5>
      <div className="price">
      {product.oldPrice && 
        <p className='old'>
          £{product.oldPrice}
        </p>
      }
      <p>
        £{product.price}
      </p>
      </div>
    </div>
  )
}

export default Product