import "./WhatsappConnect.scss";
import { ReactComponent as WhatsappIcon } from "../../assets/images/whatsapp.svg";
import { useContext, useState } from "react";
import { ShopContext } from "../../Context/ShopContext";

const WhatsappConnect = () => {
  const { websiteInfos } = useContext(ShopContext);
  const [showWhatsapp, setShowWhatsapp] = useState(true);
  const handleIconClick = () => {
    const phoneNumber = websiteInfos?.whatsapp; // Replace with your phone number
    const url = `https://wa.me/${phoneNumber}`;
    window.open(url);
  };
  return showWhatsapp ? (
    <div className="whatsapp-connect">
      <div className="dismiss" onClick={()=>setShowWhatsapp(false)}>
        <i className="fas fa-times-circle"></i>
      </div>
      <WhatsappIcon className="icon" onClick={handleIconClick} />
    </div>
  ) : null;
};

export default WhatsappConnect;
