import { createBrowserRouter } from "react-router-dom";
import Home from "./pages/Home";
import SignDesigner from "./pages/SignDesigner";
import Layout from "./components/Layout/Layout";
import Shop from "./pages/Shop";
import ProductDetails from "./pages/ProductDetails";
import GetQuote from "./pages/GetQuote";
import Checkout from "./pages/Checkout";
import Category from "./pages/Category";
// import FAQ from "./pages/FAQ";
// import Privacy from "./pages/Privacy";
import BusinessLogo from "./pages/BusinessLogo";
import OutdoorSigns from "./pages/OutdoorSigns";
import ContactUs from "./pages/ContactUs";
import Signages from "./pages/Signages";
import SignageDetails from "./pages/SignageDetails";
import { Suspense, lazy } from "react";
import MainLoader from "./components/MainLoader/MainLoader";

const FAQ = lazy(()=> import('./pages/FAQ'));
const Privacy = lazy(()=>import('./pages/Privacy'));


export const router = createBrowserRouter([
    {
        path:'/',
        element: <Layout/>,
        children:[
            {
                path:'/',
                element:<Home/>
            },
            {
                path:'/custom-sign-designer',
                element:<SignDesigner/>
            },
            {
                path:'/signs-for-sales',
                element:<Shop/>
            },
            {
                path:'/product/:slug',
                element:<ProductDetails/>
            },
            {
                path:'/get-quote',
                element:<GetQuote/>
            },
            {
                path:'/outdoor-signs',
                element:<OutdoorSigns/>
            },
            {
                path:'/business-logo',
                element:<BusinessLogo/>
            },
            {
                path:'/checkout',
                element:<Checkout/>
            },
            {
                path:'category/:slug',
                element:<Category/>
            },
            {
                path: '/faq',
                element: (
                    <Suspense fallback={<MainLoader/>}>
                        <FAQ/>
                    </Suspense>
                )
            },
            {
                path: '/contact-us',
                element:<ContactUs/>
            },
            {
                path: '/privacy-policy',
                element:(
                    <Suspense fallback={<MainLoader/>}>
                        <Privacy/>
                    </Suspense>
                )
            },
            {
                path: '/signages',
                element: <Signages/>
            },
            {
                path:'signages/:slug',
                element:<SignageDetails/>
            },
        ]
    }
])