const categories = [
    {
        id:1,
        title:'First Category',
        subtitle:'Buy Neon Signs Here',
        description:'First Category Description, whe are testing the categories features and adding our touch here , Buy Neon Signs Here',
        slug:'category-1',
        banner:'category1-banner.webp',
    },
    {
        id:2,
        title:'Second Category',
        subtitle:'Buy Neon Signs Here',
        description:'Second Category Description goes here, whe are testing the categories features and adding our touch here , Buy Neon Signs Here',
        slug:'category-2',
        banner:'category2-banner.webp',
    },
]

export default categories;