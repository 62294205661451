import { RouterProvider } from "react-router-dom";
import { router } from "./router";
import ShopContextProvider from "./Context/ShopContext";



function App() {
  return (
    <ShopContextProvider>
      <RouterProvider router={router}>
      </RouterProvider>
    </ShopContextProvider>

  );
}

export default App;
