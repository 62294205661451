import { useContext } from 'react'
import ProductInformations from '../components/ProductInformatons/ProductInformations'
import { useParams } from 'react-router-dom';
import BreadCrumbs from '../components/BreadCrumbs/BreadCrumbs';
import { ShopContext } from '../Context/ShopContext';

const ProductDetails = () => {
    const {slug} = useParams();
    const {websiteInfos} = useContext(ShopContext);
    const product = websiteInfos.products.find((product) => product.slug === slug);
    // const category = websiteInfos.categories.find((category) => category.id === product.category_id);
    const breadcrumbs = [
        {label: 'Home' , link: '/'},
        {label: 'Shop' , link: '/signs-for-sales'},
        // {label: category.title , link: `/category/${category.slug}`},
        {label: product.title}
    ]
  return (
    <>
        <BreadCrumbs breadcrumbs={breadcrumbs}/>
        <ProductInformations product={product} />
    </>
  )
}

export default ProductDetails