import { useState } from "react";
import "./GetQuoteForm.scss";
import FileUpload from "../FileUpload/FileUpload";
import Button from "../Button/Button";
import { useForm } from "react-hook-form";
import useSwalAlert from "../../hooks/useSwalAlert";

const GetQuoteForm = ({ BlueOcean, Type = "quote" }) => {
  const showAlert = useSwalAlert();
  const {
    register,
    formState: { errors, isSubmitting, isSubmitSuccessful },
    handleSubmit,
  } = useForm();

  const [outdoor, setOutdoor] = useState(false);
  const [selectedBudge, setSelectedBudge] = useState(200);
  const [uploadedFiles, setUploadedFiles] = useState([]);

  const handleFilesUploaded = (files) => {
    setUploadedFiles(files);
  };

  const onSubmit = async (data) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/quote/store`;
      let formBody = new FormData();

      formBody.append("firstName", data.firstName);
      formBody.append("lastName", data.lastName);
      formBody.append("phone", data.phone);
      formBody.append("email", data.email);
      formBody.append("informations", data.informations);
      formBody.append("outdoor", outdoor);
      formBody.append("budge", selectedBudge);
      formBody.append("size", data.size);
      formBody.append("type", Type);

      uploadedFiles.forEach((file, index) => {
        formBody.append(`files[${index}]`, file);
      });

      const options = {
        method: "POST",
        body: formBody,
      };
      await fetch(url, options);
      showAlert(
        "Success",
        "Your request was sent successfully",
        "success",
        null,
        "/"
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <div className={`get-quote-form ${BlueOcean ? "blue-ocean" : ""}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="form-container">
          <div className="form-input">
            <div className="label">First Name</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="First Name"
                {...register("firstName", {
                  required: true,
                  maxLength: 20,
                  pattern: /^[A-Za-z]+$/i,
                })}
              />
            </div>
            {errors?.firstName?.type === "required" && (
              <span style={{ color: "red" }}>Please fill up this field</span>
            )}
            {errors?.firstName?.type === "maxLength" && (
              <span style={{ color: "red" }}>
                First name cannot exceed 20 characters
              </span>
            )}
          </div>
          <div className="form-input">
            <div className="label">Last Name</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="Last Name"
                {...register("lastName", {
                  required: true,
                  maxLength: 20,
                  pattern: /^[A-Za-z]+$/i,
                })}
              />
            </div>
            {errors?.lastName?.type === "required" && (
              <span style={{ color: "red" }}>Please fill up this field</span>
            )}
            {errors?.lastName?.type === "maxLength" && (
              <span style={{ color: "red" }}>
                Last name cannot exceed 20 characters
              </span>
            )}
          </div>
          <div className="form-input">
            <div className="label">Email</div>
            <div className="input-container">
              <input
                type="email"
                placeholder="Email"
                {...register("email", {
                  required: true,
                  pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                })}
              />
            </div>
            {errors?.email?.type === "required" && (
              <span style={{ color: "red" }}>Please fill up this field</span>
            )}
            {errors?.email?.type === "pattern" && (
              <span style={{ color: "red" }}>Invalid email address</span>
            )}
          </div>
          <div className="form-input">
            <div className="label">Phone Number</div>
            <div className="input-container">
              <input
                type="text"
                placeholder="PhoneNumber"
                {...register("phone", {
                  required: true,
                  pattern: /^\+?[1-9]\d{1,14}$/,
                })}
              />
            </div>
            {errors?.phone?.type === "required" && (
              <span style={{ color: "red" }}>Please fill up this field</span>
            )}
            {errors?.phone?.type === "pattern" && (
              <span style={{ color: "red" }}>Invalid phone number</span>
            )}
          </div>
          {/* {Type !== "quote" && Type !=="outdoor" && (
            <div className="form-input">
              <div className="label">Indoor/Outdoor</div>
              <div className="indoor-outdoor">
                <div
                  className={`indoor ${!outdoor ? "active" : ""}`}
                  onClick={() => {
                    setOutdoor(false);
                  }}
                >
                  Indoor
                </div>
                <div
                  className={`outdoor ${outdoor ? "active" : ""}`}
                  onClick={() => {
                    setOutdoor(true);
                  }}
                >
                  Outdoor
                </div>
              </div>
            </div>
          )} */}
          <div className="form-input">
            <div className="label">Indoor/Outdoor</div>
            <div className="indoor-outdoor">
              <div
                className={`indoor ${!outdoor ? "active" : ""}`}
                onClick={() => {
                  setOutdoor(false);
                }}
              >
                Indoor
              </div>
              <div
                className={`outdoor ${outdoor ? "active" : ""}`}
                onClick={() => {
                  setOutdoor(true);
                }}
              >
                Outdoor
              </div>
            </div>
          </div>
          <div className="form-input">
            <div className="label">Approximate size (length/height)</div>
            <div className="input-container">
              <input
                {...register("size", {
                  required: true,
                })}
                type="text"
                placeholder="Approximate size"
              />
            </div>
            {errors?.size?.type === "required" && (
              <span style={{ color: "red" }}>Please fill up this field</span>
            )}
          </div>
          <div className="form-input content">
            <div className="label">
              Tell us as much as you can about your new Custom Neon sign
            </div>
            <div className="input-container textarea">
              <textarea
                placeholder="• Wording / design (or attach an image)
• Colours and fonts
• Any other requirements / notes
• How soon do you need the sign?"
                {...register("informations", {
                  required: true,
                })}
              ></textarea>
              {errors?.informations?.type === "required" && (
                <span style={{ color: "red" }}>Please fill up this field</span>
              )}
            </div>
          </div>
          <div className="form-input form-input-range">
            <div className="label">BUDGET IDEA</div>
            <div className="input-container input-range">
              <input
                type="range"
                value={selectedBudge}
                onChange={(e) => {
                  setSelectedBudge(e.target.value);
                }}
                min={200}
                max={2000}
              />
              <h6 className="selected-budge">£{selectedBudge}</h6>
            </div>
          </div>
          <div className="form-input form-input-files">
            <div className="label">Upload Your Files Here</div>
            <div className="input-container">
              <FileUpload onFilesUploaded={handleFilesUploaded} />
            </div>
          </div>
          <div className="form-submit">
            {!isSubmitSuccessful && (
              <Button
                type="submit"
                className={`submit ${
                  Object.keys(errors).length > 0 ? "error" : ""
                }`}
              >
                {isSubmitting ? "Submitting..." : "Get A Quote"}
              </Button>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default GetQuoteForm;
