import "./SignageModal.scss";
import { ReactComponent as CloseIcon } from "../../assets/images/x-circle.svg";
import Button from "../Button/Button";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Scrollbar,
  A11y,
  Autoplay,
  EffectCoverflow,
} from "swiper/modules";
import { useContext } from "react";
import { ShopContext } from "../../Context/ShopContext";
import { v4 } from "uuid";
import useSwalAlert from "../../hooks/useSwalAlert";

const SignageModal = ({ signage, onClose }) => {
    const images = signage?.images ? JSON.parse(signage.images) : [];
    const {addToCart} = useContext(ShopContext);
    const showAlert = useSwalAlert();

    const handleAddToCart = ()=>{
        const item = {
            type:'signage',
            // ...signage,
            signageId: signage.id,
            title:signage.title,
            price:signage.price,
            image:signage.image,
            quantity:1,
            itemId: v4()
        }
        addToCart(item);
        showAlert('Item was added','','');
        onClose();

    }
  return (
    <div className="signage-modal">
      <CloseIcon className="close" onClick={onClose} />
      <div className="product">
        <div className="gallery">
          <Swiper
            modules={[
              Navigation,
              Pagination,
              Scrollbar,
              A11y,
              Autoplay,
              EffectCoverflow,
            ]}
            slidesPerView={1}
            autoplay
            navigation
          >
            <SwiperSlide>
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${signage.image}`}
                alt=""
              />
            </SwiperSlide>
            
            {(images).map((image,index)=>(
                <SwiperSlide key={index}>
                    <img src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${image}`} alt={signage.title} />
                </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="details">
          <h3>{signage.title}</h3>
          {signage?.oldPrice && <h5 className="old">£{signage.oldPrice}</h5>}
          <h5>£{signage.price}</h5>
          <p>{signage.description}</p>
          <Button onClick={()=>{handleAddToCart();}}>Add To Cart</Button>
        </div>
      </div>
    </div>
  );
};

export default SignageModal;
