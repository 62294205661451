import { useContext } from 'react'
import Product from '../Product/Product'
import './FeaturedProducts.scss'
import { ShopContext } from '../../Context/ShopContext'

const FeaturedProducts = () => {
  const {websiteInfos} = useContext(ShopContext);
  const filteredProducts = websiteInfos.products.filter(product => product.featured === "1");

  return (
    <div className='featured-products'>
      <div className="content">
        {filteredProducts.map((product)=>(
          <Product FromHome product={product} key={product.id}/>
        ))}
      </div>
    </div>
  )
}

export default FeaturedProducts