import { useForm } from "react-hook-form";
import Button from "../Button/Button";
import "./ContactUsForm.scss";
import useSwalAlert from "../../hooks/useSwalAlert";

const ContactUsForm = () => {
  const showAlert = useSwalAlert();
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm();

  const onSubmit = async (formData) => {
    try {
      const url = `${process.env.REACT_APP_API_URL}/feeds/store`;
      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(formData),
      };

      const response = await fetch(url, options);
      const data = await response.json();
      if(data?.status)
      {
          showAlert("your feed was sent successfully");
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="contact-us-form">
        <div className="input-container">
          <input
            type="text"
            placeholder="Enter Your First Name"
            {...register("firstName", { required: true })}
          />
          {errors?.firstName?.type === "required" && (
            <span className="error">This field is required</span>
          )}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Enter Your Last Name"
            {...register("lastName", { required: true })}
          />
          {errors?.lastName?.type === "required" && (
            <span className="error">This field is required</span>
          )}
        </div>
        <div className="input-container">
          <input
            type="text"
            placeholder="Enter Your Email Address"
            {...register("email", {
              required: true,
              pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            })}
          />
          {errors?.email?.type === "required" && (
            <span className="error">This field is required</span>
          )}
          {errors?.email?.type === "pattern" && (
            <span className="error">Invalid email format</span>
          )}
        </div>
        <div className="input-container">
          <textarea
            placeholder="Please tell us how can we help you ?"
            {...register("message", { required: true })}
          ></textarea>
          {errors?.message?.type === "required" && (
            <span className="error">This field is required</span>
          )}
        </div>
        <Button type="submit" className="submit">
          {isSubmitting ? <i className="fa fa-spinner fa-spin"></i> : "Submit"}
        </Button>
      </div>
    </form>
  );
};

export default ContactUsForm;
