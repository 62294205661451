import "./WhyChooseUs.scss";

const WhyChooseUs = ({ Options }) => {
  if (Options.length === 0) {
    return null;
  }
  return (
    <div className="why-choose-us">
      {Options.map((option) => (
        <div className="content" key={option.id}>
          <h4 className="title">
            {option.title}
          </h4>
          <p className="description">
            {option.description}
          </p>
        </div>
      ))}
    </div>
  );
};

export default WhyChooseUs;
