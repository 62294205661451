import './GridBanner.scss'
// import Button from '../Button/Button';
// import { useNavigate } from 'react-router-dom';

const GridBanner = ({firstImage , secondImage}) => {
  // const navigate = useNavigate();
  // const isMobile = window.matchMedia("(pointer: coarse)").matches;
  // const banner1Click = ()=>{
  //   if(isMobile){
  //     setTimeout(() => {
  //         navigate('/business-logo');
  //     }, 2100);
  //   }
  //   else{
  //     navigate('/business-logo');
  //   }
  // }
  // const banner2Click = ()=>{
  //   if(isMobile){
  //     setTimeout(() => {
  //         navigate('/signs-for-sales');
  //     }, 2100);
  //   }
  //   else{
  //     navigate('/signs-for-sales');
  //   }
  // }
  // const banner3Click = ()=>{
  //   if(isMobile){
  //     setTimeout(() => {
  //         navigate('/signs-for-sales');
  //     }, 2100);
  //   }
  //   else{
  //     navigate('/signs-for-sales');
  //   }
  // }
  return (
    <div className='grid-banner'>
        <div className="banner">
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${firstImage}`} alt="neon shop" />
            {/* <Button neon className="banner-button neon pink" onClick={banner1Click}>Online Designer</Button> */}
        </div>
        <div className="banner">
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${secondImage}`} alt="neon shop" />
            {/* <Button neon className="banner-button neon sunny" onClick={banner2Click}>Business And Logos</Button> */}
        </div>
        {/* <div className="banner">
            <img src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${thirdImage}`} alt="neon shop" />
            <Button neon className="banner-button neon sunny" onClick={banner3Click}>Online Store</Button>
        </div> */}
    </div>
  )
}

export default GridBanner