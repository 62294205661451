import "./Cart.scss";
import { ReactComponent as CancelIcon } from "../../assets/images/x-circle-fill.svg";
import { ReactComponent as CartEmpty } from "../../assets/images/bag-x.svg";
import { useContext } from "react";
import { ShopContext } from "../../Context/ShopContext";
import Button from "../Button/Button";
// import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import useSwalAlert from "../../hooks/useSwalAlert";

const Cart = () => {
  const showAlert = useSwalAlert();
  const {
    formState: { isSubmitting },
    handleSubmit,
  } = useForm();
  const {
    setShowCart,
    cartItems,
    setCartItems,
    removeFromCart,
    cartItemsCount,
  } = useContext(ShopContext);
  const handleDecreaseQuantity = (productId) => {
    const updatedCartItems = cartItems.map((product) => {
      if (product.itemId === productId && product.quantity > 1) {
        return { ...product, quantity: product.quantity - 1 };
      }
      return product;
    });
    setCartItems(updatedCartItems);
  };
  const handleIncreaseQuantity = (productId) => {
    const updatedCartItems = cartItems.map((product) => {
      if (product.itemId === productId) {
        return { ...product, quantity: product.quantity + 1 };
      }
      return product;
    });
    setCartItems(updatedCartItems);
  };
  const totalAmount = cartItems.reduce((total, item) => {
    return total + item.price * item.quantity;
  }, 0);
  // const navigate = useNavigate();

  // const handleCheckoutClick = () => {
  //   setShowCart(false);
  //   navigate("/checkout");
  // };

  const onSubmit = async (formData) => {
    try {
      if (cartItems.length <= 0) {
        showAlert("there is no items in your cart", "", "error");
        return;
      }
      const allData = {
        ...formData,
        cartItems,
        total: totalAmount,
      };
      const url = `${process.env.REACT_APP_API_URL}/stripe/checkout`;
      const headers = {
        "Content-Type": "application/json",
      };
      const options = {
        method: "POST",
        headers: headers,
        body: JSON.stringify(allData),
      };

      const response = await fetch(url, options);
      const data = await response.json();
      if (data?.url) {
        window.location.href = data.url;
      } else {
        showAlert("somthing went wrong", "", "error");
      }
      setShowCart(false);
    } catch (error) {
      console.error(error);
      setShowCart(false);
    }
  };
  return (
    <div className="cart">
      <CancelIcon
        className="cancel-icon"
        onClick={() => {
          setShowCart(false);
        }}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="popup">
          {!cartItemsCount && <CartEmpty className="cart-empty" />}
          <div className="cart-items">
            {cartItems.map((product, index) => (
              <div className="cart-item" key={index}>
                {product.image ? (
                  <div className="item-image">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${product.image}`}
                      alt={product.title}
                    />
                  </div>
                ) : (
                  <div className="item-image-empty">
                    {product.type === 'sign' && product.customNeonImage && (<img src={product.customNeonImage} alt="" />)}
                  </div>
                )}

                <div className="item-informations">
                  {product.title && <h5 className="title">{product.title}</h5>}
                  {product.price && product.quantity && (
                    <h5 className="price">
                      Price:{" "}
                      <span>
                        £
                        {parseFloat(product.price * product.quantity).toFixed(
                          2
                        )}
                      </span>
                    </h5>
                  )}
                  {product.selectedSize && (
                    <h6 className="size">Size: {product.selectedSize}</h6>
                  )}
                  {product.selectedColor && (
                    <h6 className="color">Color: {product.selectedColor}</h6>
                  )}
                  {product.quantity && product.itemId && (
                    <div className="quantity">
                      <span>Quantity: {product.quantity}</span>
                      <div className="quantity-change">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleDecreaseQuantity(product.itemId);
                          }}
                        >
                          -
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            handleIncreaseQuantity(product.itemId);
                          }}
                        >
                          +
                        </button>
                      </div>
                    </div>
                  )}
                  <div className="remove">
                    <Button
                      className="remove-button"
                      onClick={() => {
                        removeFromCart(product);
                      }}
                      PD
                    >
                      Remove
                    </Button>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {cartItemsCount !== 0 && (
            <div className="checkout">
              <h5 className="price">
                Total: £{parseFloat(totalAmount).toFixed(2)}
              </h5>
              <Button
                // onClick={() => {
                //   handleCheckoutClick();
                // }}
                type="submit"
              >
                {isSubmitting ? (
                  <i className="fa fa-spinner fa-spin"></i>
                ) : (
                  "Checkout"
                )}
              </Button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
};

export default Cart;
