import './CategoryBanner.scss'

const CategoryBanner = ({Banner , Title}) => {
  return (
    <div className='category-banner'>
        <div className="layout">
            <div className="title">
                {Title}
            </div>
        </div>
        <img src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${Banner}`} alt="Neon Shop" />
    </div>
  )
}

export default CategoryBanner