import ContactUsForm from "../components/ContactUsForm/ContactUsForm";
import MainPageImageBanner from "../components/MainPageImageBanner/MainPageImageBanner";
import ContactUsImage from "../assets/images/contact-us.jpg";

const ContactUs = () => {
  const subtitle = `Sky Sign FOR HIGH QUALITY SIGNS
  `;
  const subtitles = [
    "Is your business getting the most from its signage?",
    "For reliable services from a professional, experienced, and reactive team of sign makers, contact Sky Sign. Since 1990 we have been providing companies, schools, businesses, and organisations throughout Manchester and the UK with high-quality signs. We can provide you with a full range of signage solutions and graphic services.",
    "Call or email us today.",
    "Free Delivery when you order £500 or more!"
  ];
  return (
    <>
      <MainPageImageBanner
        image={ContactUsImage}
        title="CONTACT OUR EXPERT"
        subtitle={subtitle}
        subtitles={subtitles}
      />
      <ContactUsForm />
    </>
  );
};

export default ContactUs;
