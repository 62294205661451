import './FileUpload.scss';
import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
const FileUpload = ({onFilesUploaded}) => {
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      // File size validation
      const maxSize = 20000; // In KB
      const filteredFiles = acceptedFiles.filter((file) => file.size <= maxSize * 1024);

      // File type validation
      const allowedTypes = ['ai', 'eps', 'gif', 'jpg', 'jpeg', 'pdf', 'png', 'tiff'];
      const filteredFilesWithType = filteredFiles.filter((file) => allowedTypes.includes(file.name.split('.').pop()));

      // Maximum number of files validation
      const maxFiles = 4;
      const slicedFiles = filteredFilesWithType.slice(0, maxFiles);

      setUploadedFiles(slicedFiles);

      onFilesUploaded(slicedFiles);

      // Display an alert for invalid files
      if (acceptedFiles.length !== slicedFiles.length) {
        setErrorMessage('Invalid file(s) detected. Please check the file size and type.');
        setTimeout(() => {
          setErrorMessage('');
        }, 3000); // Clear the error message after 3 seconds
      }
    },
  });

  return (
    <div className='file-upload' {...getRootProps()}>
      <input {...getInputProps()} />
      <h6>Drag and drop files here or click to browse.</h6>
      <p>Max file size: 20000 KB | Allow file types: ai, eps, gif, jpg, jpeg, pdf, png, tiff | Max number of files: 4</p>
      <ul>
        {uploadedFiles.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </ul>
      {errorMessage && <div className='alert'>{errorMessage}</div>}
    </div>
  );
};
export default FileUpload;