import BreadCrumbs from "../components/BreadCrumbs/BreadCrumbs";
import PageHeader from "../components/PageHeader/PageHeader";
// import GetQuoteForm from "../components/GetQuoteForm/GetQuoteForm";
import Video from "../assets/images/sinages-video.mp4";
import TempImage from "../assets/images/sky-sign-with-logo.png";
import PageVideoBanner from "../components/PageVideoBanner/PageVideoBanner";
import { useEffect, useState } from "react";
import SignagesContainer from "../components/SignagesContainer/SignagesContainer";
import MainLoader from "../components/MainLoader/MainLoader";

const OutdoorSigns = () => {
  const [signages , setSignages] = useState([]);
  const [isLoading , setIsLoading] = useState(true);
  
  useEffect(()=>{
    const fetchContent = async () =>{
      try{
        const url = `${process.env.REACT_APP_API_URL}/signage/outdoor`;
        const response = await fetch(url);
        const data = await response.json();
        setSignages(data.data);
        setIsLoading(false);
      } catch(error){
        console.log(error);
        setIsLoading(false);
      }
    }
    fetchContent();
  },[])


  const breadcrumbs = [
    { label: "Home", link: "/" },
    { label: "Outdoor Signs" },
  ];
  if(isLoading)
  {
    return(<MainLoader/>);
  }
  return (
    <>
      <PageVideoBanner Video={Video} Image={TempImage} Title={"Outdoor Signs"} />
      <BreadCrumbs breadcrumbs={breadcrumbs} />
      <PageHeader Center Title={"Get Your Outdoor Signs"} />
      <SignagesContainer Signages={signages}/>
      {/* <GetQuoteForm Type="outdoor"/> */}
    </>
  );
};

export default OutdoorSigns;
