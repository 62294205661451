import { Link } from 'react-router-dom'
import './BreadCrumbs.scss'

const BreadCrumbs = ({breadcrumbs}) => {
  return (
    <div className='bread-crumbs'>
        {breadcrumbs.map((breadcrumb,index)=>(
            <span key={index}>
            {index > 0 && <span className="separator">/</span>}
            {breadcrumb.link ? (
            <Link to={breadcrumb.link}>{breadcrumb.label}</Link>
            ) : (
            <span>{breadcrumb.label}</span>
            )}
            </span>
        ))}
    </div>
  )
}

export default BreadCrumbs