import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

const useSwalAlert = () => {
    const navigate = useNavigate();
    const showAlert = (title, text, icon = 'success',after, redirectTo , confirmButtonText = 'Ok') => {
        Swal.fire({
          title,
          text,
          icon,
          confirmButtonText: confirmButtonText,
        }).then((result)=>{
            if(result.isConfirmed)
            {
                if(after)
                {
                    after();
                }
                if(redirectTo)
                {
                    navigate(redirectTo);
                }
            }
        });
      };
    
      return showAlert;
}

export default useSwalAlert