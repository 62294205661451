import './Heading.scss'

const Heading = ({title,subTitle}) => {
  return (
    <div className='heading'>
        <h3 className='title'>
            {title}
        </h3>
        {subTitle && 
            <p className='subtitle'>
                {subTitle}
            </p>
        }
    </div>
  )
}

export default Heading