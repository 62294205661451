import "./SignagesContainer.scss";
import { useState } from "react";
import SignageModal from "../SignageModal/SignageModal";

const SignagesContainer = ({ Signages }) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedSignage, setSelectedSignage] = useState(null);

  const handleClick = (signage) => {
    setSelectedSignage(signage);
    setModalVisible(true);
  };

  const handleClose = () => {
    setModalVisible(false);
    setSelectedSignage(null);
  };
  return (
    <>
      <div className="signages-container">
        {Signages.map((signage, index) => (
          <div
            className="signage"
            key={index}
            onClick={() => {
              handleClick(signage);
            }}
          >
            <div className="image-container">
              <img
                src={`${process.env.REACT_APP_PUBLIC_URL}/storage/${signage.image}`}
                alt={signage.title}
              />
              <div className="description">
                <p>{signage.description}</p>
              </div>
            </div>
            <div className="informations">
              <h3>{signage.title}</h3>
              {/* {signage?.oldPrice && (
                <p className="old">
                  £{parseFloat(signage.price).toFixed(2)}
                </p>
              )} */}
              <p className="starting-from">
                Starting from
              </p>
              <p>£{parseFloat(signage.price).toFixed(2)}</p>
            </div>
          </div>
        ))}
      </div>
      {modalVisible && <SignageModal signage={selectedSignage} onClose={handleClose}/>}
    </>
  );
};

export default SignagesContainer;
